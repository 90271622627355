import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxButton from '../Shared/LaxButton';
 
class About extends React.Component {
    
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="about-area ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <span>About Hurun UAE Summit 2023</span>
                                <h2 className='titelsH2'>The Most Important Event in the <b>UAE</b></h2>
                                <p className='parg'>The Hurun awards are given on a yearly basis to recognize organizations and individuals who have made outstanding achievements in their own business fields as well as people who have made significant contributions to the community.</p>
                                <br />
                                
                                <p className='parg'>Experience the latest and newest innovations from UAE's brightest minds that are revolutionizing different industries from Film making to real estate and beyond.</p>

                                {/* <div style={{visibility: 'hidden'}} className="signature">
                                    <img src={require("../../assets/images/HURUN ME logo 4.svg")} alt="signature" />
                                </div> */}

                                <div className='actionAbout'>
                                    <Link to="/about-hurun" className="btn btn-primary">
                                        Read More 
                                        <i className="icofont-double-right"></i>
                                    </Link>

                                    <Link to="/join-hurun" className="btn btn-secondary">Join The Event</Link>
 
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/sample.jpg")} className="about-img1" alt="about" />

                                {/* <img style={{visibility: 'hidden'}} src={require("../../assets/images/hurun.jpg")} className="about-img2" alt="about" /> */}

                                <img src={require("../../assets/images/logo 2.png")} className="shape-img" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;