import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
 
class uploadDocuments extends React.Component {
    state = {
        uploaded: []
    };


    setAnswerChangeHandler = (qus,ans) => {
        let extraFields = Object.assign({}, this.state.extraFields);
        extraFields[qus] = ans;
        this.setState({extraFields});
    }

    Submission = (event)=> {
        event.preventDefault();
        this.props.onSubmit(event,this.state.uploaded)
    }
    render(){
        return (
            <>
                <div style={{textAlign: 'center',marginBottom: '30px'}}><span>Upload your Documents * (Your picture,Company profile)</span></div>
                <form  onSubmit={this.Submission} id="basicQuestionFrom">

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <UploadFiles uploaded={this.state.uploaded} />
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <button type="submit" className="btn btn-primary">Submit application</button>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                </form>
            </>
        )}
    
}

export default uploadDocuments