import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxButton from '../Shared/LaxButton';
 
class About extends React.Component {
    
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="about-area ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h2>Sangsbeel Group</h2>
                                
                                <p style={{textAlign: 'justify'}}>Ms. Hongxiang Qi, is the Chief Executive Officer of Sangsbeel Group, writer, screenwriter, film and television producer. She has more than 25 years of business experience in the UAE and has established her own companies, covering industries including labor service export, theatrical performance planning, construction, consulting, films and television investment.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>Ms. Qi, one of the few women who boldly and confidently ran a building contracting company, Sangsbeel Contracting, in the Gulf Region, a business sector dominated by men. Her company was involved in the construction of a total of 69 building contracting projects across the UAE. Sangsbeel Contracting took part in the subcontracting works of some of the notable projects in the UAE such as Burj Khalifa, Emirates Hills, Al Reem Island, Yas Island, Palm Jumeirah Island, Al Hamra Village, among others.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>She has profound influence and a good resource for contacts in the UAE government agencies and business circles. In addition, she has also shown outstanding talent in the field of literary creation.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>She has written and published the autobiographical novel "A Wisteria Blooming in Dubai", which is to be aired in China and the UAE. Moreover, she also served as one of the screenwriters and planners of the TV series of the same name, and actively prepared for the production of the TV series.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>She has always been committed to promoting exchanges between China and the UAE, and spared no effort to make positive contributions to the friendly communication between the two nations.</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/image00001.jpg")} style={{top: '0px',maxHeight: '400px'}} className="about-img1" alt="about" />

                            </div>
                        </div>
                    </div>
                </div>
				<div className="container" style={{marginTop : '100px'}}>
                    <div className="row h-120 align-items-center">
						<div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/sangsbeel.jpg")} style={{top: '0px',right : '0px',maxHeight: '400px'}} className="about-img1" alt="about" />

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <a href="https://sangsbeelbc.com/" target="_blank"><h2>Sangsbeel Business Center DMCC</h2></a> 
                                
                                <p style={{textAlign: 'justify'}}>Sangsbeel Business Center DMCC in Mazaya Business Avenue Towers is situated in the heart of Dubai's luxurious location in Jumeirah Lakes Towers (JLT) which offers landscaping upper deck views of Dubai Marina, Jumeirah Islands, JLT Lakes, and golf course.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>The business centers offer fully furnished serviced-offices and flexi-desks for Start-ups, Entrepreneurs and business owners.
									Its bright, inspiring office spaces are designed to help teams of all sizes and configurations for interviews, huddle group, off-site, board meeting, training or negotiations.
									Without multiple level of support and business set-up solutions, Sangsbeel Business Center DMCC provides national and international companies with one-stop solution to their office requirements.</p>
                            </div>
                        </div>

                    </div>
                </div>
				<div className="container" style={{marginTop : '100px'}}>
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                            <a href="https://www.sangsbeelgroup.com/" target="_blank"><h2>Sangsbeel Business DMCC</h2></a>
                                
                                <p style={{textAlign: 'justify'}}>Sangsbeel Business DMCC is established in 2014 with its license issued by Dubai Multi Commodities Centre (DMCC) Authority in Jumeirah Lakes Towers, Dubai.
									 The company specializes in Managing Offices, Public Relations Management, Media Studies & Consultancies, and Corporate Services Provider (CSP), both locally and globally.
									 The company has an extensive experience and full-scope assistance on company formation and its related services with expertise in DMCC company establishment tailored for the client's specific business needs,
									  aiming exceptional quality services in a cost-competitive manner, and wide range-range experience in the UAE market.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>With confidentially and efficiency, Sangsbeel Business DMCC takes pride in helping business owners and investors and by providing a large variety of consultancy services to local and international clients</p>
                                 
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/sangsbeeldmcc.jpg")} style={{top: '0px',maxHeight: '400px'}} className="about-img1" alt="about" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;