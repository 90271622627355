import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
import InputNationality from '../inputs/Nationality/Nationality';
class BasicQuestion extends React.Component {
    formFields =  {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            age: '',
            nationality: '',
            gender: '',
            annualIncome : '',
            poBox : '',
            media : []
        }
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields : this.formFields
    };

    fieldsChangeHandler = (e,field) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields[field] = e;
        this.setState({formFields});
    }

    genderChangeHandler = (g) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.gender = g;
        this.setState({formFields});
    }

    Submission = (event)=> {
        event.preventDefault();
        let formFields = Object.assign({}, this.state.formFields);
        console.log('----> formFields ->',formFields)
        this.props.onSubmit(event,formFields)
        this.setState({formFields : this.formFields});
    }
    onUploadImages = (media) =>{
        console.log('----> media ->',media)
    }
    setNationality = (nationality)=>{
        this.fieldsChangeHandler(nationality,'nationality')
    }
    render(){
        return (
            <form  onSubmit={this.Submission} id="basicQuestionFrom">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>First Name<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name='fname'
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your first name" 
                                                    value={this.state.formFields.firstName}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'firstName')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Last Name<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 

                                                    className="form-control" 
                                                    required={true} 
                                                    name='lname'
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your last name" 
                                                    value={this.state.formFields.lastName}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'lastName')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Email Address<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="email" 
                                                    className="form-control inputLower" 
                                                    required={true} 
                                                    name='email'
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'email')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    required={true} 
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'phone')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Age<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="number" min="14" max="100"
                                                    className="form-control" 
                                                    required={true} 
                                                    data-error="Please enter your age" 
                                                    value={this.state.formFields.age}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'age')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your nationality<span style={{color : 'red'}}>*</span></label>
                                                <InputNationality className='form-control' value={this.setNationality} />
                                                {/* <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your nationality" 
                                                    value={this.state.formFields.nationality}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'nationality')}
                                                /> */}
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} onChange={()=> console.log('')} type="text" required={true} value={this.state.formFields.gender} />
                                                <label>Choose your gender<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseGander'>
                                                    <div className="form-check">
                                                        <input id="Check-Male" className="form-check-input" type="radio" 
                                                        checked={this.state.formFields.gender == 'M' ? true : null} name="gender" value="M"
                                                        onChange={() => this.genderChangeHandler('M')} />
                                                        <label className="form-check-label" htmlFor="Check-Male">Male</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-Female" className="form-check-input" type="radio"
                                                        checked={this.state.formFields.gender == 'F' ? true : null} name="gender" value="F"
                                                        onChange={() => this.genderChangeHandler('F')} />
                                                        <label className="form-check-label" htmlFor="Check-Female">Female</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter your annual income (USD)<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="number" 
                                                    className="form-control"  
                                                    required={true} 
                                                    data-error="Please enter your annual income" 
                                                    value={this.state.formFields.annualIncome}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'annualIncome')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>{"Enter your P.O Box (Optional)"}</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.formFields.poBox}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'poBox')}
                                                />
                                            </div>
                                        </div>
                                        {
                                            
                                               this.props.upload ? 
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Upload your picture (It will be used in your event badge)</label>
                                                            
                                                            <UploadFiles uploaded={this.state.formFields.media} />
                                                            
                                                        </div>
                                                    </div>
                                                : <></>
                                            
                                        }
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">{this.props.buttonText}</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                    </form>
        )}
    
}

export default BasicQuestion