
import React from 'react';
import ReactDOM from 'react-dom';
 
function Table(props) {
    if (props.tab == 1) {
        return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Wealth US $bn</th>
            <th>Main Company</th>
            <th>Age</th>
        </tr>
    </thead>
    <tbody> 
  
        <tr>
            <td>Elon Musk</td>
            <td>205</td>
            <td>Tesla</td>
            <td>50</td>
        </tr>
                    
        <tr>
            <td>Jeff Bezos</td>
            <td>188</td>
            <td>Amazon</td>
            <td>58</td>
        </tr>
                    
        <tr>
            <td>Bernard Arnault</td>
            <td>153</td>
            <td>LVMH</td>
            <td>73</td>
        </tr>
                    
        <tr>
            <td>Bill Gates </td>
            <td>124</td>
            <td>Microsoft</td>
            <td>66</td>
        </tr>
                    
        <tr>
            <td>Warren Buffett</td>
            <td>119</td>
            <td>Berkshire Hathaway</td>
            <td>91</td>
        </tr>
                    
        <tr>
            <td>Sergey Brin</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Larry Page</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Steve Ballmer</td>
            <td>107</td>
            <td>Microsoft</td>
            <td>65</td>
        </tr>
                    
        <tr>
            <td>Mukesh Ambani</td>
            <td>103</td>
            <td>Reliance</td>
            <td>64</td>
        </tr>
                    
        <tr>
            <td>Bertrand Puech&amp;family</td>
            <td>102</td>
            <td>Hermes</td>
            <td>86</td>
        </tr>
    </tbody>
</table>;
    }else if (props.tab == 2) {
        return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Wealth US $bn</th>
            <th>Main Company</th>
            <th>Country</th>
        </tr>
    </thead>
    <tbody> 
    <tr>
                    <td>Li Ka-shing &amp; family</td>
                    <td>33</td>
                    <td>Cheung Kong</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Lee Shau Kee</td>
                    <td>27</td>
                    <td>Henderson Land</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Yang Huiyan &amp; family</td>
                    <td>26</td>
                    <td>Country Garden</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Peter Woo Kwong-China &amp;family</td>
                    <td>18</td>
                    <td>Wheelock</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Wang Jianlin &amp; family</td>
                    <td>17</td>
                    <td>Wanda</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Wu Yajun &amp; family</td>
                    <td>17</td>
                    <td>Longfor</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Hugh Grosvenor</td>
                    <td>16</td>
                    <td>Grosvenor</td>
                    <td>UK</td>
                </tr>
                             
                <tr>
                    <td>Donald Bren</td>
                    <td>15</td>
                    <td>Irvine Company</td>
                    <td>USA</td>
                </tr>
                             
                <tr>
                    <td>Joseph Lau Luen Hung &amp; family</td>
                    <td>14</td>
                    <td>Chinese Estates</td>
                    <td>China</td>
                </tr>
                             
                <tr>
                    <td>Li Hua</td>
                    <td>11</td>
                    <td>Excellence</td>
                    <td>China</td>
                </tr>
    </tbody>
</table>;
    }else if (props.tab == 3) {
        return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Company</th>
            <th>Industry</th>
        </tr>
    </thead>
    <tbody> 
    <tr>
                    <td>Vitalik Buterin</td>
                    <td>28</td>
                    <td>Ethereum</td>
                    <td>Cryptocurrency</td>
                </tr>
                             
                <tr>
                    <td>Alex Rodrigues</td>
                    <td>27</td>
                    <td>Embark Trucks</td>
                    <td>Technology</td>
                </tr>
                             
                <tr>
                    <td>Shelby Yes</td>
                    <td>27</td>
                    <td>RockMass Technologies</td>
                    <td>Technology</td>
                </tr>
                             
                <tr>
                    <td>Mathew Gubasta</td>
                    <td>26</td>
                    <td>RockMass Technologies</td>
                    <td>Technology</td>
                </tr>
                             
                <tr>
                    <td>Francis Davidson</td>
                    <td>27</td>
                    <td>Sonder Corp.</td>
                    <td>Hospitality</td>
                </tr>
                             
                <tr>
                    <td>Lucas Pellan</td>
                    <td>29</td>
                    <td>Sonder Corp.</td>
                    <td>Hospitality</td>
                </tr>
                             
                <tr>
                    <td>Aaron Spivak</td>
                    <td>27</td>
                    <td>Hush Blankets</td>
                    <td>Manufacture&amp;Retail</td>
                </tr>
                             
                <tr>
                    <td>Lior Ohayon</td>
                    <td>29</td>
                    <td>Hush Blankets</td>
                    <td>Manufacture&amp;Retail</td>
                </tr>
                             
                <tr>
                    <td>Cameron Dearsley</td>
                    <td>29</td>
                    <td>Drop Technologies</td>
                    <td>Technology</td>
                </tr>
                             
                <tr>
                    <td>Josh Baker</td>
                    <td>29</td>
                    <td>Fraction Technologies</td>
                    <td>Technology</td>
                </tr>
                             
                <tr>
                    <td>Hayden James</td>
                    <td>29</td>
                    <td>Fraction Technologies</td>
                    <td>Technology</td>
                </tr>
    </tbody>
</table>;
    }else if (props.tab == 4) {
        return <table className="table">
    <thead>
        <tr>
            <th>Company</th>
            <th>Wealth US $bn</th>
            <th>Location</th>
            <th>Industry</th>
        </tr>
    </thead>
    <tbody> 
  
    <tr>
                    <td>Dapper Labs</td>
                    <td>480 </td>
                    <td>Vancouver</td>
                    <td>Blockchain</td>
                </tr>
                             
                <tr>
                    <td>SSENSE</td>
                    <td>265 </td>
                    <td>Montreal</td>
                    <td>E-commerce</td>
                </tr>
                             
                <tr>
                    <td>Wealthsimple</td>
                    <td>260</td>
                    <td>Toronto</td>
                    <td>Financial Technology</td>
                </tr>
                             
                <tr>
                    <td>Hopper</td>
                    <td>225 </td>
                    <td>Montreal</td>
                    <td>E-commerce</td>
                </tr>
                             
                <tr>
                    <td>ApplyBoard</td>
                    <td>205 </td>
                    <td>Ontario</td>
                    <td>Educational Technology</td>
                </tr>
                             
                <tr>
                    <td>Blockstream</td>
                    <td>205 </td>
                    <td>Victoria</td>
                    <td>Blockchain</td>
                </tr>
                             
                <tr>
                    <td>1Password</td>
                    <td>130 </td>
                    <td>Ontario</td>
                    <td>Cybersecurity</td>
                </tr>
                             
                <tr>
                    <td>Clearco</td>
                    <td>130 </td>
                    <td>Toronto</td>
                    <td>Financial Technology</td>
                </tr>
                             
                <tr>
                    <td>Trulioo</td>
                    <td>115 </td>
                    <td>Vancouver</td>
                    <td>Cybersecurity</td>
                </tr>
                             
                <tr>
                    <td>Clio</td>
                    <td>105 </td>
                    <td>Burnaby</td>
                    <td>Software services</td>
                </tr>
    </tbody>
</table>;
    }
    

}

function Table2(props) {
    return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Wealth US $bn</th>
            <th>Main Company</th>
            <th>Age</th>
        </tr>
    </thead>
    <tbody> 
  
        <tr>
            <td>Elon Musk</td>
            <td>205</td>
            <td>Tesla</td>
            <td>50</td>
        </tr>
                    
        <tr>
            <td>Jeff Bezos</td>
            <td>188</td>
            <td>Amazon</td>
            <td>58</td>
        </tr>
                    
        <tr>
            <td>Bernard Arnault</td>
            <td>153</td>
            <td>LVMH</td>
            <td>73</td>
        </tr>
                    
        <tr>
            <td>Bill Gates </td>
            <td>124</td>
            <td>Microsoft</td>
            <td>66</td>
        </tr>
                    
        <tr>
            <td>Warren Buffett</td>
            <td>119</td>
            <td>Berkshire Hathaway</td>
            <td>91</td>
        </tr>
                    
        <tr>
            <td>Sergey Brin</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Larry Page</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Steve Ballmer</td>
            <td>107</td>
            <td>Microsoft</td>
            <td>65</td>
        </tr>
                    
        <tr>
            <td>Mukesh Ambani</td>
            <td>103</td>
            <td>Reliance</td>
            <td>64</td>
        </tr>
                    
        <tr>
            <td>Bertrand Puech&amp;family</td>
            <td>102</td>
            <td>Hermes</td>
            <td>86</td>
        </tr>
    </tbody>
</table>;
}

function Table3(props) {
    return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Wealth US $bn</th>
            <th>Main Company</th>
            <th>Age</th>
        </tr>
    </thead>
    <tbody> 
  
        <tr>
            <td>Elon Musk</td>
            <td>205</td>
            <td>Tesla</td>
            <td>50</td>
        </tr>
                    
        <tr>
            <td>Jeff Bezos</td>
            <td>188</td>
            <td>Amazon</td>
            <td>58</td>
        </tr>
                    
        <tr>
            <td>Bernard Arnault</td>
            <td>153</td>
            <td>LVMH</td>
            <td>73</td>
        </tr>
                    
        <tr>
            <td>Bill Gates </td>
            <td>124</td>
            <td>Microsoft</td>
            <td>66</td>
        </tr>
                    
        <tr>
            <td>Warren Buffett</td>
            <td>119</td>
            <td>Berkshire Hathaway</td>
            <td>91</td>
        </tr>
                    
        <tr>
            <td>Sergey Brin</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Larry Page</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Steve Ballmer</td>
            <td>107</td>
            <td>Microsoft</td>
            <td>65</td>
        </tr>
                    
        <tr>
            <td>Mukesh Ambani</td>
            <td>103</td>
            <td>Reliance</td>
            <td>64</td>
        </tr>
                    
        <tr>
            <td>Bertrand Puech&amp;family</td>
            <td>102</td>
            <td>Hermes</td>
            <td>86</td>
        </tr>
    </tbody>
</table>;
}

function Table4(props) {
    return <table className="table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Wealth US $bn</th>
            <th>Main Company</th>
            <th>Age</th>
        </tr>
    </thead>
    <tbody> 
  
        <tr>
            <td>Elon Musk</td>
            <td>205</td>
            <td>Tesla</td>
            <td>50</td>
        </tr>
                    
        <tr>
            <td>Jeff Bezos</td>
            <td>188</td>
            <td>Amazon</td>
            <td>58</td>
        </tr>
                    
        <tr>
            <td>Bernard Arnault</td>
            <td>153</td>
            <td>LVMH</td>
            <td>73</td>
        </tr>
                    
        <tr>
            <td>Bill Gates </td>
            <td>124</td>
            <td>Microsoft</td>
            <td>66</td>
        </tr>
                    
        <tr>
            <td>Warren Buffett</td>
            <td>119</td>
            <td>Berkshire Hathaway</td>
            <td>91</td>
        </tr>
                    
        <tr>
            <td>Sergey Brin</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Larry Page</td>
            <td>116</td>
            <td>Alphabet</td>
            <td>48</td>
        </tr>
                    
        <tr>
            <td>Steve Ballmer</td>
            <td>107</td>
            <td>Microsoft</td>
            <td>65</td>
        </tr>
                    
        <tr>
            <td>Mukesh Ambani</td>
            <td>103</td>
            <td>Reliance</td>
            <td>64</td>
        </tr>
                    
        <tr>
            <td>Bertrand Puech&amp;family</td>
            <td>102</td>
            <td>Hermes</td>
            <td>86</td>
        </tr>
    </tbody>
</table>;
}

class TableResponsive extends React.Component {

    componentDidMount() {
        console.log("--------> ",this.props.tab)
    }
    
    componentWillUnmount() {
        
    }
    
    render(){
        return (
            <div className="table-responsive text-center">
                <Table tab={this.props.tab}></Table>
            </div>
        );
    }
}

 
export default TableResponsive;