import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import TableResponsive from '../Shared/TableResponsive';
import SchedulesTable from '../Shared/ScheduleTable';
 
class EventSchedules extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section className="schedule-area bg-image ptb-120">
                <div className="container">
                    <div className="section-title">
                        <span>Event Schedule</span>
                        <h2>Hurun <b>UAE Summit 2023</b> Schedule of Events</h2>

                        <LaxDiv text="Schedule" dataPreset="driftLeft" />

                        <Link to="/join-hurun" className="btn btn-primary">Join Hurun UAE Summit</Link>

                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <SchedulesTable />
                        </div>

                        <div className="col-lg-12">
                            <div className="btn-box">
                                <a href="https://hurunuae.ae/admin/doc/Proposed%20Hurun%20UAE%20Summit%202023%20Program.pdf"  download={true} className="btn btn-primary">Download Schedule (PDF)</a>
                                <a href="https://www.instagram.com/hurunuae/"  target="_blank" className="btn btn-secondary">Connect Via Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div>
                <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div>
                <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div>
                <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div>
            </section>
        );
    }
}
 
export default EventSchedules;