import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import "./assets/css/all.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/bootstrap-icons.css";

import "./assets/css/bootstrap-icons.css";
import BlogThumb from "./assets/images/event/ed-thumb.png";
import GallaryIem3 from "./assets/images/gallary/e-gallary-l1.png";
import GallaryIem4 from "./assets/images/gallary/e-gallary-l2.png";
import GallaryIem2 from "./assets/images/gallary/e-gallary-sm1.png";
import GallaryIem1 from "./assets/images/gallary/e-gallary1.png";
// Gallary View image import
import GallaryView1 from "./assets/images/gallary/view1.png";
import GallaryView2 from "./assets/images/gallary/view2.png";
// reply button icon
import ReplyBtnIcon from "./assets/images/icons/reply-icon.png";
// commentor image import
import Commentor1 from "./assets/images/speaker/commentor-1.png";
import Commentor2 from "./assets/images/speaker/commentor-2.png";
import Commentor3 from "./assets/images/speaker/commentor-3.png";
// main speaker image import
import MainSeaker1 from "./assets/images/speaker/event-sm1.png";
import MainSeaker2 from "./assets/images/speaker/event-sm2.png";
import MainSeaker3 from "./assets/images/speaker/event-sm3.png";
import MainSeaker4 from "./assets/images/speaker/event-sm4.png";
import MainSeaker5 from "./assets/images/speaker/event-sm5.png";
import MainSeaker6 from "./assets/images/speaker/event-sm6.png";
// speaker image import
import speaker1 from "./assets/images/speaker/speaker-sm1.png";
import speaker2 from "./assets/images/speaker/speaker-sm2.png";
import speaker3 from "./assets/images/speaker/speaker-sm3.png";
import speaker4 from "./assets/images/speaker/speaker-sm4.png";
// main sponser logo image import
import sponsorLogo1 from "./assets/images/sponsor/ed-sponsor1.png";
import sponsorLogo2 from "./assets/images/sponsor/ed-sponsor2.png";
import sponsorLogo3 from "./assets/images/sponsor/ed-sponsor3.png";
import sponsorLogo4 from "./assets/images/sponsor/ed-sponsor4.png";
import EventSchedules from "../HomeDefault/EventSchedules";
import SchedulesTable from "../Shared/ScheduleTable";
import Pricing from "../Pricing/Pricing";
import Speakers from "../HomeDefault/Speakers";
class EventDetailsWrapper extends Component {
  componentDidMount(){
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  openTabSection = (evt, tabNmae) => {
      let i, tabcontent, tablinks;
      tabcontent = document.querySelectorAll(".tab-pane");
      for (i = 0; i < tabcontent.length; i++) {
          console.log(tabcontent[i])
          tabcontent[i].className = tabcontent[i].className.replace(" show active", "");
      }

      tablinks = document.querySelectorAll(".nav-link");
      for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      document.getElementById(tabNmae).className += " show active";
      evt.target.className += " active";
  }
  render() {
    return (
      <>
        <div >
          {/* className="event-details-wrapper item-bg-event" */}
          <div className="container pt-24 position-relative" style={{marginBottom: '50px'}}>
            <div className="ed-main-wrap">
                <div className="ed-top">
                  <div className="ed-thumb">
                    <img src={require('../../assets/images/Photo+0054-2023.png')} alt="Blog details" />
                  </div>
                  <ul className="ed-status">
                    <li>
                      <i className="bi bi-calendar2-week" /> March 17-19, 2023
                    </li>
                    <li className="active">
                      <i className="bi bi-diagram-3" /> <span>500</span> Seat
                    </li>
                    <li>
                      <i className="bi bi-pin-map" />
                      United Arab Emirates, Abu Dhabi 
                    </li>
                  </ul>
                  <div className="event-info row align-items-center">
                    <div className="col-lg-3 col-md-4">
                      <div className="single-event-info">
                        <div className="info-icon">
                          <i className="bi bi-blockquote-left" />
                        </div>
                        <div className="info-content">
                          <h5>Event Type</h5>
                          <p>Exhibition & Conference</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-event-info">
                        <div className="info-icon">
                          <i className="bi bi-megaphone" />
                        </div>
                        <div className="info-content">
                          <h5>Speaker</h5>
                          <p>15 Best Speaker</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-event-info">
                        <div className="info-icon">
                          <i className="bi bi-lightning" />
                        </div>
                        <div className="info-content">
                          <h5>Strategic Partner</h5>
                          <p>Abu Dhabi Department of Economic Development</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="single-event-info">
                        <div className="info-icon">
                        <i className="icofont-network-tower"></i>
                        </div>
                        <div className="info-content">
                          <h5>Partner</h5>
                          <p>Abu Dhabi Department of Culture and Tourism</p>
                        </div>
                      </div>
                    </div>
   
                  </div>
                </div>
                <div className="ed-tabs-wrapper">
                  <div className="tabs-pill">
                    <ul
                      className="nav nav-pills justify-content-center"
                      id="pills-tab2"
                      role="tablist">
                      <li className="nav-item" role="presentation"
                      onClick={(e) => this.openTabSection(e, 'pills-details')}>
                        <button className="nav-link active" id="pills-details-tab">
                          {" "}
                          <i className="bi bi-info-circle" /> <span>Details</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation" 
                      onClick={(e) => this.openTabSection(e, 'pills-schedule')}>
                        <button className="nav-link" id="pills-schedule-tab" type="button" role="tab" >
                          <i className="bi bi-calendar3" /> <span>Schedule</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation"
                      onClick={(e) => this.openTabSection(e, 'pills-gallary')}
                      >
                        <button className="nav-link" id="pills-gallary-tab" type="button" role="tab">
                          <i className="bi bi-images" /> <span>Gallery</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation"
                      onClick={(e) => this.openTabSection(e, 'pills-booking')}
                      >
                        <button className="nav-link" id="pills-booking-tab" type="button" role="tab">
                        <i className="bi bi-tags-fill" />
                        <span>Booking</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent2">
                    <div
                      className="tab-pane fade show active"
                      id="pills-details"
                      role="tabpanel"
                      aria-labelledby="pills-details-tab">
                      <div className="details-tab-content">
                        <h3 className="ed-title">
                        1st Ever HURUN Summit in UAE
                        </h3>
                        <p>
                        The Hurun Summit in the Middle East is a luxury event organized by the Hurun Research Institute, a China-based research firm that publishes annual lists of the wealthiest individuals and companies in China and the Middle East. The summit brings together some of the wealthiest individuals and companies in the Middle East for a night of entertainment, luxury and networking opportunities.
                        The Emirates Palace is an extravagant architectural masterpiece in the Middle East featuring traditional Arabic design mixed with modern luxury. The evening will also include award ceremonies honouring companies in various categories, a drone show,	and a fireworks display with laser lights and projection mapping on the palace's facade. The event promises to be a night of entertainment and luxury
                        </p>
                        <div className="row ed-overview">
                          <div className="col-lg-6">
                            <h5 className="ed-subtitle">Overview</h5>
                            <ul className="overview-list">
                              <li>
                                <i className="bi bi-check2" /> Award ceremonies, which honor companies and individuals in various categories
                              </li>
                              <li>
                                <i className="bi bi-check2" /> Keynote speeches and panel discussions from business leaders and entrepreneurs, who share their insights and experiences
                              </li>
                              <li>
                                <i className="bi bi-check2" /> Entertainment such as drone shows and fireworks displays with laser lights and projection mapping on the venue's facad
                              </li>
                              <li>
                                <i className="bi bi-check2" /> Networking opportunities for attendees to connect with each other and with the honored companies and individuals
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-6">
                            <div className="overview-images d-grid">
                              <div className="overview-img1">
                                <img
                                  src={require('../../assets/images/Photo+0803.jpg')}
                                  alt="Blog details"
                                  className="img-fluid fitImages"
                                />
                              </div>
                              <div className="overview-img2">
                                <img
                                  src={require('../../assets/images/Photo+0684.jpg')}
                                  alt="Blog details"
                                  className="img-fluid fitImages"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <p>
                        The Hurun Summit in the Middle East is typically held in prestigious venues like the Emirates Palace in Abu Dhabi, which is known for its extravagant architecture and traditional Arabic design mixed with modern luxury.
                         The event is considered to be a platform for the business leaders and entrepreneurs to share their insights and experiences with the attendees.
                        </p>
                        <Speakers />
                        {/* <div className="speakers-list">
                          <h5 className="ed-subtitle">Main Speaker</h5>
                          <div className="row ">
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker1} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>John Loganin</h6>
                                  <strong>Digital Marketing</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker2} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>Jackson Levi</h6>
                                  <strong>CTO</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker3} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>Victoria Lily</h6>
                                  <strong>Marketing</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker4} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>Hannah Emilia</h6>
                                  <strong>Marketing</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker5} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>Sebastian Mateo</h6>
                                  <strong>Founder</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-speaker">
                                <div className="speaker-image">
                                  <img src={MainSeaker6} alt="Blog details" />
                                </div>
                                <div className="speaker-info">
                                  <h6>Willow Lucy</h6>
                                  <strong>Co Founder</strong>
                                  <ul className="speaker-social-links">
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-instagram" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={this.scrollTop} to={"#"}>
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-schedule"
                      role="tabpanel"
                      aria-labelledby="pills-schedule-tab" style={{paddingTop : '38px'}}>
                      <SchedulesTable />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-gallary"
                      role="tabpanel"
                      aria-labelledby="pills-gallary-tab">
                      <div className="gallary-tab-content">
                      <SRLWrapper>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="gallary-item">
                              <img src={require('../../assets/images/Photo+0327.jpg')} alt="Gallery" />
                              <Link to={require('../../assets/images/Photo+0327.jpg')}>
                                <img
                                  src={require('../../assets/images/Photo+0327.jpg')}
                                  className="gallary-item-overlay"
                                  alt="GallaryIMAGE"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="gallary-item">
                              <img src={require('../../assets/images/Photo+0857.jpg')} alt="Gallery" />
                              <Link to={require('../../assets/images/Photo+0857.jpg')}>
                                <img
                                  src={require('../../assets/images/Photo+0857.jpg')}
                                  className="gallary-item-overlay"
                                  alt="GallaryIMAGE"
                                />
                              </Link>
                            </div>
                            <div className="gallary-item">
                              <img src={require('../../assets/images/Photo+0803.jpg')} alt="Gallery" />
                              <Link to={require('../../assets/images/Photo+0803.jpg')}>
                                <img
                                  src={require('../../assets/images/Photo+0803.jpg')}
                                  className="gallary-item-overlay"
                                  alt="GallaryIMAGE"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="gallary-item">
                              <img src={require('../../assets/images/Photo+0389.jpg')} alt="Gallery" />
                              <Link to={require('../../assets/images/Photo+0389.jpg')}>
                                <img
                                  src={require('../../assets/images/Photo+0389.jpg')}
                                  className="gallary-item-overlay"
                                  alt="GallaryIMAGE"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="gallary-item">
                              <img src={require('../../assets/images/Photo+0705.jpg')} alt="Gallery" />
                              <Link to={require('../../assets/images/Photo+0705.jpg')}>
                                <img
                                  src={require('../../assets/images/Photo+0705.jpg')}
                                  className="gallary-item-overlay"
                                  alt="GallaryIMAGE"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      
                        </SRLWrapper>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-booking"
                      role="tabpanel"
                      aria-labelledby="pills-booking-tab">
                          <div className="row" style={{marginTop: '60px'}}>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>Regular Visitor</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Exhibition Access</li>
                                    <li>Conference Access</li>
                                    <li>Sponsors booth Access</li>
                                    <li>Attendance Certificate</li>
                                    <li>Custom Badge</li>
                                    <li>Discussion Panel Access</li>
                                    {/* <li></li> */}
                                </ul>
                                
                                <Link to="/apply" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>VIP Guest</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Gala dinner Table</li>
                                    <li>Vip Tour</li>
                                    <li>Private Invitation</li>
                                    <li>Sample</li>
                                    <li>Sample</li>
                                    <li>Discussion Panel Access</li>
                                </ul>
                                
                                <Link to="/applyVIP" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>Sponsor</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Exhibition Booth</li>
                                    <li>Guests Invitations</li>
                                    <li>Gala Dinner Tables</li>
                                    <li>Advertisement</li>
                                    <li>Discussion Panel time</li>
                                    <li>Pages In Hurun Magazine</li>
                                </ul>
                                
                                <Link to="#" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>
                    </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventDetailsWrapper;
