import React from 'react';

class AskAnnualIncome extends React.Component {
    state = {
                 notNext : true,
                 showSecond : false
            }

    getSelected = (newState)=>{
       this.setState({notNext : newState})
    }
    goToNext = ()=>{
        let pos = document.getElementsByClassName('stepsDiv')[0].offsetTop
        window.scrollTo({top: pos-70, behavior: 'smooth'});
        console.log('go to ---> ',pos)
        this.setState({showSecond : true})
    }
    render(){
    return (<>
        <div style={{display: (this.state.showSecond ? 'none' : 'initial')}}  onKeyDown={(event) => event.key === 'Enter' && !this.state.notNext ? this.goToNext() : ''}>
            <div role="tabpanel" style={{padding: '20px',boxShadow: '0px 0px 20px rgba(108,118,134,0.1)'}} >

                <span style={{fontSize: '32px'}}>What's your Annual Income ? *</span>

                <div className="form-check" style={{marginTop: '10px'}}>
                    <input id="formCheck-0" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(true)} />
                    <label className="form-check-label" htmlFor="formCheck-0" style={{fontSize: '20px'}}> Below 100,000$ </label>
                </div>
                <div className="form-check">
                    <input id="formCheck-1" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(false)}/>
                    <label className="form-check-label" htmlFor="formCheck-1" style={{fontSize: '20px'}}>100,000$ - 200,000$</label>
                </div>
                <div className="form-check">
                    <input id="formCheck-4" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(false)} />
                    <label className="form-check-label" htmlFor="formCheck-4" style={{fontSize: '20px'}}>200,000$ - 300,000$</label>
                </div>
                <div className="form-check">
                    <input id="formCheck-3" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(false)} />
                    <label className="form-check-label" htmlFor="formCheck-3" style={{fontSize: '20px'}}>300,000$ - 400,000$</label>
                </div>
                <div className="form-check">
                    <input id="formCheck-2" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(false)} />
                    <label className="form-check-label" htmlFor="formCheck-2" style={{fontSize: '20px'}}>400,000$ - 500,000$</label>
                </div>
                <div className="form-check">
                    <input id="formCheck-5" className="form-check-input" type="radio" name='annualIncome' onClick={()=> this.getSelected(false)} />
                    <label className="form-check-label" htmlFor="formCheck-5" style={{fontSize: '20px'}}>More than 500,000$</label>
                </div>
                <button className="btn btn-primary" type="button" style={{marginTop: '50px'}} disabled={this.state.notNext} onClick={()=> this.goToNext()}>Next</button>
            </div>
        </div>
       

        <div style={{ display: (this.state.showSecond ? 'initial' : 'none') }}>
            <this.props.second onSubmit={this.props.onSubmit}/>
        </div>
        
        </>
    )}
}

export default AskAnnualIncome;