import React from 'react'
class StepsTabs extends React.Component {
    
    state = {
        steps : this.props.steps,
        progress : '0%',
        curructStep : 0,
        data : [],
    }

    componentDidMount(){
        //this.props.steps[0].pass = ''
        this.props.steps[0].active = ''
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.querySelectorAll(".tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            console.log(tabcontent[i])
            tabcontent[i].className = tabcontent[i].className.replace(" show active", "");
        }
  
        tablinks = document.querySelectorAll(".nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
  
        document.getElementById(tabNmae).className += " show active";
        // evt.target.className += " active";
    }
    gotoStartApply = ()=>{
        let pos = document.getElementsByClassName('stepsDiv')[0].offsetTop
        window.scrollTo({top: pos-70, behavior: 'smooth'});
    }
    next = (event,data)=>{
        this.gotoStartApply()
        this.props.steps[this.state.curructStep].active = null
        this.props.steps[this.state.curructStep]['pass'] = ''
        this.state.data.push(data)
        let nextstep = this.state.curructStep+1

        // alert('go Next' + this.props.steps.length + '   <--->  ' + nextstep)

		

        if (this.props.steps.length == nextstep) {
			if(!('plan' in this.props)) this.props['plan'] = ''
            console.log(this.state)

			let data = {
				steps : this.state.data,
				type : this.props.postAs,
				plan : this.props.plan
				
			}
			fetch('https://hurunuae.ae/admin/api.php', {
				method: 'POST',
				mode: 'no-cors',
				headers: { mode: 'no-cors','Content-Type': 'application/x-www-form-urlencoded' },
				body: new URLSearchParams({data: JSON.stringify(data)}),
			}).then(ret => console.log(ret))
            this.props.onSubmit()
        }else{
            this.props.steps[nextstep].active = ''
            this.state.curructStep++
            this.setState({steps : this.props.steps})
            if (nextstep < 4) this.openTabSection(event,'step-'+nextstep)
        }

        
     
        
    }
    render(){
        return (<>
            <div className="stepsDiv" style={{step: this.state.progress}}>
                {this.props.steps.map((step,index) => {
                    //return <input key={index} className="steps" onClick={(e) => this.openTabSection(e, 'step-'+index)} type="radio" step={step.text} />
                    console.log('--------> ',step.text,' <> ',index)
                    // return <div key={index} className="border rounded-circle d-lg-flex justify-content-lg-center align-items-lg-center steps"
                    //             onClick={(e) => this.openTabSection(e, 'step-'+index)} step={step.text} ch={this.state.steps[index].pass}>
                    //             <div className="border rounded-circle"></div>
                    //         </div>
                    return <div key={'stpkey-'+index} className="border rounded-circle d-lg-flex justify-content-lg-center align-items-lg-center steps" 
                    step={step.text} pass={this.state.steps[index].pass} active={this.state.steps[index].active} >
                                <div className="border rounded-circle"></div>
                            </div>

                })}
            </div>
            <div className="tab-content" id="pills-tabContent2" style={{padding: '40px 20% 0 20%'}}>
                {this.props.steps.map((step,index) => {
                    return <div key={index} className={"tab-pane fade" + (index == 0 ? ' show active' : '')} id={'step-'+index} role="tabpanel">{
                         <step.tab second={step.second} onSubmit={this.next} />}</div>
                })}
            </div>
            </>
        )}
    
}

export default StepsTabs