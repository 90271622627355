import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
 
class SponsorFirstQuestion extends React.Component {
    organiztionFields = {
            name: '',
            type: '',
            industry: '',
            country: '',
            city: '',
            website: '',
            email: '',
            phone: '',
            poBox: '',
        }
    state = {
        organiztionFields: this.organiztionFields
    };

    fieldsChangeHandler = (e,field) => {
        let organiztionFields = Object.assign({}, this.state.organiztionFields);
        organiztionFields[field] = e.target.value;
        this.setState({organiztionFields});
    }

    companySizeChangeHandler = (size) => {
        let organiztionFields = Object.assign({}, this.state.organiztionFields);
        organiztionFields.size = size;
        this.setState({organiztionFields});
    }

    Submission = (event)=> {
        event.preventDefault();
        let organiztionFields = Object.assign({}, this.state.organiztionFields);
        this.props.onSubmit(event,organiztionFields)
        this.setState({organiztionFields : this.organiztionFields});
    }
    render(){
        return (
            <form  onSubmit={this.Submission} id="basicQuestionFrom">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your organization Name<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z0-9 ]+"
                                                    data-error="Please enter your organization" 
                                                    value={this.state.organiztionFields.name}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'name')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your organization Type<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z0-9 ]+"
                                                    data-error="Please enter your organization Type" 
                                                    value={this.state.organiztionFields.type}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'type')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter organization Industry<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z0-9 ]+"
                                                    data-error="Please enter organization industry" 
                                                    value={this.state.organiztionFields.industry}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'industry')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter organization Country<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter organization Country" 
                                                    value={this.state.organiztionFields.country}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'country')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter organization city<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter organization city" 
                                                    value={this.state.organiztionFields.city}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'city')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter organization Website<span style={{color : 'red'}}>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control inputLower"  
                                                    required={true} 
                                                    data-error="Please enter organization Website" 
                                                    value={this.state.organiztionFields.website.toLowerCase() }
                                                    onChange={(e) => this.fieldsChangeHandler(e,'website')}
                                                    pattern="^(http:\/\/www\.|https:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Email address<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="email" 
                                                    required={true} 
                                                    className="form-control" 
                                                    value={this.state.organiztionFields.email}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'email')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your phone number<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="number" 
                                                    required={true} 
                                                    className="form-control" 
                                                    value={this.state.organiztionFields.phone}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'phone')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter your P.O Box</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={this.state.organiztionFields.poBox}
                                                    onChange={(e) => this.fieldsChangeHandler(e,'poBox')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Next</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                    </form>
        )}
    
}

export default SponsorFirstQuestion