import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Pricing extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="pricing-area ptb-120 bg-image">
                <div className="container">
                    <div className="section-title">
                        <span>Join Hurun</span>
                        <h2>Be A Part of Hurun <b>UAE Summit 2023</b></h2>

                        <LaxDiv text="SUMMIT" dataPreset="driftLeft" />

                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>Regular Visitor</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Exhibition Access</li>
                                    <li>Conference Access</li>
                                    <li>Sponsors booth Access</li>
                                    <li>Attendance Certificate</li>
                                    <li>Custom Badge</li>
                                    <li>Discussion Panel Access</li>
                                    {/* <li></li> */}
                                </ul>
                                
                                <Link to="/apply" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>VIP Guest</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Gala dinner Table</li>
                                    <li>Vip Tour</li>
                                    <li>Private Invitation</li>
                                    <li>Hotel Accommodation</li>
                                    <li>Flight Ticket</li>
                                    <li>Discussion Panel Access</li>
                                </ul>
                                
                                <Link to="/applyVIP" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="pricing-table-box">
                                <div className="pricingTable-header">
                                    <h3 className="title" style={{marginTop : '20px',marginBottom : '50px'}}>Sponsor</h3>
                                </div>
                                
                                <ul className="pricing-content">
                                    <li>Exhibition Booth</li>
                                    <li>Guests Invitations</li>
                                    <li>Gala Dinner Tables</li>
                                    <li>Advertisement</li>
                                    <li>Discussion Panel time</li>
                                    <li>Pages In Hurun Magazine</li>
                                </ul>
                                
                                <Link to="/applySponsorPage" className="btn btn-primary">Apply Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Pricing;