import React, {Component } from "react";
import "./style.css";
import countries from './countries.json';

class InputNationality extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            input : '',
            open : false,
            firstOne : ''
        }
        this.Nationalitys = countries
      }
    
    setValue = (v)=>{
        this.setState({input : v})
        this.props.value(v)
    }
    onChangeHandler = (e)=>{
        this.setValue(e.target.value)
    }
    selectNationality = (nationality)=>{
        this.setState({open : false})
        this.setValue(nationality)
    }

    render() {
        let firstOne = ''
        return (
            <div className="inputSearch">
                <input 
                type="text" 
                ref={this.myRef}
                placeholder="Search" 
                className={this.props.className} 
                onKeyDown={(event) => event.key === 'Enter' ? this.selectNationality(firstOne) : ''}
                onChange={this.onChangeHandler}
                onFocus={()=> this.setState({open : true})}
                onBlur={()=> setTimeout(() => {this.setState({open : false})}, 200)}
                value={this.state.input}/>
                <ul style={{display : (this.state.open ? 'flow-root' : 'none')}} >
                    {
                        this.Nationalitys.map((item,index) =>{
                            let hide = item.nationality.substring(0,this.state.input.length).toLowerCase() != this.state.input.toLowerCase()
                            if (firstOne == '' && !hide) firstOne = item.nationality
                            return <li style={{display : (hide ? 'none' : 'list-item')}} key={'li-'+index} onClick={()=> this.selectNationality(item.nationality)}>
                                        <div><img  src={require(`./svg/${item.alpha_3_code.toLowerCase()}.svg`)}/><span>{item.nationality}</span></div>
                                    </li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default InputNationality;
