import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import Footer from '../Common/Footer';
import LaxDiv from '../Shared/LaxDiv';
import About from '../AboutOne/About';
import UploadFiles from '../Shared/UploadFiles';
import BasicQuestion from '../Question/BasicQuestion';
import Successfully from '../Shared/successfully';

class VisitorApply extends React.Component {
    state = {
        submitting: false,
        submitted: false,
    };


    onSubmit = (e,fields) => {
        console.log('------ From Apply>' ,fields)
        this.setState({submitted : true})
        console.log(this.state)
		let data = {
			steps : fields,
			type : 'Visitor'
		}
		fetch('https://hurunuae.ae/admin/api.php', {
			method: 'POST',
			mode: 'no-cors',
			headers: { mode: 'no-cors','Content-Type': 'application/x-www-form-urlencoded' },
        	body: new URLSearchParams({data: JSON.stringify(data)}),
		}).then(ret => console.log(ret))

    }
	

    render(){
        return (
            <section className="speakers-area ptb-120 bg-image">
            <div className="container">
                <div className="section-title">
                    <span>HURUN EVENT</span>
                    <h2>Join Hurun UAE Summit 2023 as a <b>Visitor</b></h2>
                    <div className="bar"></div>
                    <LaxDiv text="VISTORS" dataPreset="driftRight" />
                </div>
                
            <div className="row h-100 ptb-24 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
        
                                <p>The Hurun awards are given on a yearly basis to recognize organizations and individuals who have made outstanding achievements in their own business fields as well as people who have made significant contributions to the community</p>
                                
                                <p>Experience the latest and newest innovations from UAE's brightest minds that are revolutionizing different industries from Film making to real estate and beyond.</p>

                                <div className="signature">
                                    <img style={{visibility: 'hidden'}} src={require("../../assets/images/HURUN ME logo 4.svg")} alt="signature" />
                                </div>

        
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/vigih.png")} className="about-img3" alt="about" />


                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'center',display : `${this.state.submitted ? 'none' : 'inherit'}`,fontSize: '24px',fontWeight: 800,marginBottom: '30px',marginTop: '60px'}}>Apply Now</div>
                    <div>
                        {
                            this.state.submitted ? <Successfully></Successfully> :
                            <BasicQuestion buttonText="Submit" upload={true} onSubmit={this.onSubmit} />
                        }
                        
                    </div>
                    
            </div>
            
        </section>
        );
    }
}
 
export default VisitorApply;