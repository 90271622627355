import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import Footer from '../Common/Footer';
import Modal from 'react-bootstrap/Modal';
import Successfully from '../Shared/successfully';
 
class Contact extends React.Component {
    formFields = {
        name: '',
        email: '',
        phone: '',
        text: ''
    }
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: this.formFields
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('https://hurunuae.ae/admin/email.php', {
            method: 'post',
            mode: 'no-cors',
            headers: { mode: 'no-cors','Content-Type': 'application/x-www-form-urlencoded' },
			body: new URLSearchParams({data: JSON.stringify(data)})
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            this.setState({formFields : this.formFields});
            this.setState({modal : true})
        });
        this.setState({ submitted: true })
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }


    render(){
        return (
            <React.Fragment>
                <div className="page-title-area item-bg-contact">
                    <div className="container">
                        {/* <h1>Contact Us</h1>
                        <span>Send me your Message</span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Contact</li>
                        </ul> */}
                    </div>
                </div>

                <section className="contact-area ptb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <a href="tel:+97143814222">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-phone"></i>
                                        </div>
                                        <div className="content">
                                            <h4>Phone</h4>
                                            <p>+971 4 381 4222</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="col-lg-4 col-md-6">
                                <a href="mailto:info@hurunmiddleeast.ae">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-email"></i>
                                        </div>
                                        
                                        <div className="content">
                                            <h4>E-mail</h4>
                                                <p>info@hurunmiddleeast.ae</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <a href='https://goo.gl/maps/7ggy5DGfs34r1VsY7' target="_blank">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className="icofont-world"></i>
                                        </div>
                                        
                                        <div className="content">
                                            <h4>Location</h4>
                                            <p>Dubai, JLT, Mazaya Business Avenue</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="row h-100 align-items-center contact-form">
                            <div className="col-lg-4 col-md-12">
                                <div className="leave-your-message">
                                    <h3>Leave Your Message</h3>
                                    <p>If you have any questions about the services we provide simply use the form below. We try to respond to all queries and comments within 24 hours.</p>
                                    
                                    <div className="stay-connected">
                                        <h3>Stay Connected</h3>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/hurunmiddleeastdmcc/" target="_blank">
                                                    <i className="icofont-facebook"></i>
                                                    <span>Facebook</span>
                                                </a>
                                            </li>
                                            
                                            {/* <li>
                                                <a href="#" target="_blank">
                                                    <i className="icofont-twitter"></i>
                                                    <span>Twitter</span>
                                                </a>
                                            </li> */}
                                            
                                            <li>
                                                <a href="https://www.instagram.com/hurunuae/" target="_blank">
                                                    <i className="icofont-instagram"></i>
                                                    <span>Instagram</span>
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href="https://www.linkedin.com/company/hurun-middle-east-dmcc/" target="_blank">
                                                    <i className="icofont-linkedin"></i>
                                                    <span>Linkedin</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-12">
                            {this.state.submitted ? <Successfully></Successfully> :
                                <form onSubmit={this.onSubmit} id="contactForm">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    id="name" 
                                                    required={true} 
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    required={true} 
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Phone Number<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true} 
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message<span style={{color : 'red'}}>*</span></label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="4" 
                                                    required={true} 
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Send Message</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default Contact;