import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
 
class SponsorSecondQuestion extends React.Component {
    secondFields = {
        companySize: '',
        sponsorship: ''
    }
    state = {
        secondFields: this.secondFields
    };

    companySizeChangeHandler = (size) => {
        let secondFields = Object.assign({}, this.state.secondFields);
        secondFields.companySize = size;
        this.setState({secondFields});
    }
    sponsorshipChangeHandler = (plan) => {
        let secondFields = Object.assign({}, this.state.secondFields);
        secondFields.sponsorship = plan;
        this.setState({secondFields});
    }
    

    Submission = (event)=> {
        event.preventDefault();
        let secondFields = Object.assign({}, this.state.secondFields);
        this.props.onSubmit(event,secondFields)
        this.setState({secondFields : this.secondFields});
    }
    render(){
        return (
            <form  onSubmit={this.Submission} id="basicQuestionFrom">
                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} type="text" onChange={()=>{}} required={true} value={this.state.secondFields.companySize}></input>
                                                <label>Choose Your Company Size<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseCompanySize'>
                                                    <div className="form-check">
                                                        <input id="Check-size1" className="form-check-input" type="radio" 

                                                        checked={this.state.secondFields.companySize == 'size1' ? true : null} name="CompanySize" value="size1"
                                                        onChange={(e) => this.companySizeChangeHandler('size1')} />
                                                        <label className="form-check-label" htmlFor="Check-size1">{"5 - 10 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size2" className="form-check-input" type="radio" 
                                                        checked={this.state.secondFields.companySize == 'size2' ? true : null} name="CompanySize" value="size2"
                                                        onChange={(e) => this.companySizeChangeHandler('size2')} />
                                                        <label className="form-check-label" htmlFor="Check-size2">{"15 - 20 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size3" className="form-check-input" type="radio"
                                                        checked={this.state.secondFields.companySize == 'size3' ? true : null} name="CompanySize" value="size3"
                                                        onChange={(e) => this.companySizeChangeHandler('size3')} />
                                                        <label className="form-check-label" htmlFor="Check-size3">{"25 - 40 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size4" className="form-check-input" type="radio"
                                                        checked={this.state.secondFields.companySize == 'size4' ? true : null} name="CompanySize" value="size4"
                                                        onChange={(e) => this.companySizeChangeHandler('size4')} />
                                                        <label className="form-check-label" htmlFor="Check-size4">{"50 - 100 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size5" className="form-check-input" type="radio"
                                                        checked={this.state.secondFields.companySize == 'size5' ? true : null} name="CompanySize" value="size5"
                                                        onChange={(e) => this.companySizeChangeHandler('size5')} />
                                                        <label className="form-check-label" htmlFor="Check-size5">{"100 and more"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} type="text" onChange={()=>{}} required={true}  value={this.state.secondFields.sponsorship}></input>
                                                <label>Select sponsorship type<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseCompanySize'>
                                                    <div className="form-check">
                                                        <input id="Check-type1" className="form-check-input" type="radio" 
                                                        checked={this.state.secondFields.sponsorship == 'type1' ? true : null} name="sponsorship" value="type1"
                                                        onChange={(e) => this.sponsorshipChangeHandler('type1')} />
                                                        <label className="form-check-label" htmlFor="Check-type1">Main sponsor</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-type2" className="form-check-input" type="radio" 
                                                        checked={this.state.secondFields.sponsorship == 'type2' ? true : null} name="sponsorship" value="type2"
                                                        onChange={(e) => this.sponsorshipChangeHandler('type2')} />
                                                        <label className="form-check-label" htmlFor="Check-type2">Platinum sponsor</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-type3" className="form-check-input" type="radio"
                                                        checked={this.state.secondFields.sponsorship == 'type3' ? true : null} name="sponsorship" value="type3"
                                                        onChange={(e) => this.sponsorshipChangeHandler('type3')} />
                                                        <label className="form-check-label" htmlFor="Check-type3">Gold sponsor</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-type4" className="form-check-input" type="radio"
                                                        checked={this.state.secondFields.sponsorship == 'type4' ? true : null} name="sponsorship" value="type4"
                                                        onChange={(e) => this.sponsorshipChangeHandler('type4')} />
                                                        <label className="form-check-label" htmlFor="Check-type4">Silver sponsor</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

     
                                
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Submit application</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
            </form>
        )}
    
}

export default SponsorSecondQuestion