import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop';
import getCroppedImg from "./Crop";

class CropperX extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crop: { x: 0, y: 0 },
            zoom: 1.2,
            image : URL.createObjectURL(props.imageFile),
            original : this.props.imageFile,
            croped : null
        };
    }
    CONTAINER_HEIGHT = 300
    handleCropChange = (crop) => {
        this.setState({ crop });
        }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels);
        const croppedImage = getCroppedImg(this.state.image,croppedAreaPixels)
        croppedImage.then(ret => {
            fetch(ret)
            .then(response => response.blob())
            .then(blob => {
                 console.log('1-------->',blob)
                    let file = new File([blob], 'croped_'+this.state.original.name,{type : "image/png", lastModified : new Date().getTime()});
                    file.state = 'preparing'//'uploaded'
                    file.error = false
                    file.progress = 0
                    file.id = 0
                    console.log('----Croped---->',file)
                    this.setState({croped : file})
            })
            .catch(error => {
                console.error("Error: ", error);
            });
        })
        .catch(err =>{
            console.log('======>',err)
        })
    }

    handleZoomChange = (zoom) => {
        this.setState({ zoom });
    }




    render() {
        return (
                    <div
                        className="modal show"
                        style={{ display: 'block'}}
                    >
                        
                    <Modal.Dialog>
                        <Modal.Body style={{height: '550px'}}>
                        <Cropper
                        image={this.state.image}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={2 / 2}
                        onCropChange={(crop) => this.setState({ crop })}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={(zoom) => this.setState({ zoom })}
                    />

                        </Modal.Body>
                        <div className="modal-footer" style={{display: 'block'}}>
                            <Button variant="secondary" style={{float: 'left',cursor: 'pointer'}} onClick={()=> this.props.onSave(this.props.imageFile,this.props.imageFile)}>Skip</Button>
                            <Button variant="primary" style={{float: 'right'}} onClick={()=> this.props.onSave(this.state.croped,this.props.imageFile)}>Save</Button>
                            <Button variant="secondary" style={{float: 'right'}} onClick={()=> this.props.onClose(this.props.imageFile.name)}>Close</Button>
                        </div>
                    </Modal.Dialog>
                    </div>
        );
    }
}

export default CropperX;