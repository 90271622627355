import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import Footer from '../Common/Footer';
import LaxDiv from '../Shared/LaxDiv';
import About from '../AboutOne/About';
import UploadFiles from '../Shared/UploadFiles';
import StepsTabs from '../Shared/StepsTabs';
import AskAnnualIncome from './forms/AskAnnualIncome';
import AskBasicInfo from './forms/AskbasicInfo';
import CompnyQuestion from '../Question/CompnyQuestion';
import ExtraQuestion from '../Question/ExtraQuestion';
import uploadDocuments from '../Question/uploadDocuments';
import ModalSuccess from '../Shared/ModalSuccess';
import Successfully from '../Shared/successfully';
 
class VIPVisitorApply extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };
    
    onSubmit = ()=>{
        this.setState({submitted : true})
    }

    render(){
        return (
            <section className="speakers-area ptb-120 bg-image">
            <div className="container">
                <div className="section-title">
                    <span>HURUN EVENT</span>
                    <h2>Join Hurun UAE Summit 2023 as a <b>VIP Guest</b></h2>
                    <div className="bar"></div>
                    <LaxDiv text="VIP Guest" dataPreset="driftRight" />
                </div>
      
            <div className="row h-100 ptb-24 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <p>The Hurun awards are given on a yearly basis to recognize organizations and individuals who have made outstanding achievements in their own business fields as well as people who have made significant contributions to the community</p>
                                
                                <p>Experience the latest and newest innovations from UAE's brightest minds that are revolutionizing different industries from Film making to real estate and beyond.</p>

                                <div className="signature">
                                    <img style={{visibility: 'hidden'}} src={require("../../assets/images/HURUN ME logo 4.svg")} alt="signature" />
                                </div>

        
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/Photo+0054-2023.png")} className="about-img3" alt="about" />

                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'center',display : `${this.state.submitted ? 'none' : 'inherit'}`,fontSize: '24px',fontWeight: 800,marginBottom: '30px',marginTop: '60px'}}>Apply Now</div>
                    {this.state.submitted ? <div style={{padding: '40px 20% 0 20%'}}>
                        <Successfully></Successfully>
                        </div> :
                     <StepsTabs postAs="VIPVisitor" onSubmit={this.onSubmit} plan='' steps={[
                                        {text : "Step 1",tab : AskAnnualIncome,second : AskBasicInfo},
                                        {text : "Step 2",tab : CompnyQuestion},
                                        {text : "Step 3",tab : ExtraQuestion},
                                        {text : "Step 4",tab : uploadDocuments}
                                    ]}/>       
                    }

                    
                    
            </div>
            
        </section>
        );
    }
}


function AskCompnyInfo() {
    return (
        <div>
            Ask Compny Info
        </div>
    )
}
function AskExtraInfo() {
    return (
        <div>
            Ask Extra Info
        </div>
    )
}
 
export default VIPVisitorApply;