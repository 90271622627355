import React from 'react';
import ReactDOM from 'react-dom';
import lax from 'lax.js';
 
class LaxDiv extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        lax.addElement(this.myRef.current)
    }
    
    componentWillUnmount() {
        lax.removeElement(this.myRef.current)
    }

    render(){
        return (
            <div className="bg-title lax" ref={this.myRef} data-lax-preset={this.props.dataPreset} >
                {this.props.text}
            </div>
        );
    }
}

LaxDiv.defaultProps = {
    text: 'Speakers',
    dataPreset: 'driftRight'
};
 
export default LaxDiv;