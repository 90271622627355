import React from 'react';
import { Link } from 'react-router-dom';
 
class MainBanner extends React.Component {
    render(){
        return (
            <div className="page-title-area item-bg-about">
                <div className="container">

                </div>
            </div>
        );
    }
}
 
export default MainBanner;