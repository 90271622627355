import React from 'react';
import { Link } from 'react-router-dom';
import TablePlansRow from './row';
 
class TablePlans extends React.Component {
    render(){
        return (
            <div className="s-table">
                <div className="s-table-header">
                    <label className="form-label">{this.props.titel}</label>
                </div>
                {this.props.rows.map((row,i)=>{
                    return <TablePlansRow key={'tba-'+i} titel={row.titel} content={row.content} ></TablePlansRow>
                })
                }
                <div className="s-table-row">
                    <div>
                        <label className="form-label">Join</label>
                    </div>
                    <div>
                        <Link to={"/applySponsor#"+this.props.hash} className="btn btn-primary" style={{float: 'right'}}>Apply Now</Link>
                    </div>
                </div>
        </div>
        );
    }
}
 
export default TablePlans;