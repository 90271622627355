import React from 'react';
import MainBanner from '../HurunReport/MainBanner';
import GoTop from '../Shared/GoTop';
import Footer from '../Common/Footer';
import lax from 'lax.js';
import SponsorsShipPlan from '../HurunSponsorApply/SponsorsShipPlan';
 
class ApplySponsorPage extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>
                {/* Main Banner */}
                <div className="page-title-area item-bg-sponsers">
                    <div className="container">
                        {/* <h1>Sponsors</h1>
                        <span>Check Who Makes This Event Possible!</span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Sponsors</li>
                        </ul> */}
                    </div>
                </div>
                {/* About Area */}
                <SponsorsShipPlan />
                <Footer />

                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default ApplySponsorPage;