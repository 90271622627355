import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
 
class ExtraQuestion extends React.Component {
    extraFields = {
            UAEVisa: '',
            UAETicket: '',
            Accommodation: ''
        }
    state = {
        extraFields: this.extraFields
    };


    setAnswerChangeHandler = (qus,ans) => {
        let extraFields = Object.assign({}, this.state.extraFields);
        extraFields[qus] = ans;
        this.setState({extraFields});
    }

    Submission = (event)=> {
        event.preventDefault();

        let extraFields = Object.assign({}, this.state.extraFields);
        this.props.onSubmit(event,extraFields)
        this.setState({extraFields : this.extraFields});
        
    }
    render(){
        return (
            <form  onSubmit={this.Submission} id="basicQuestionFrom">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} onChange={()=>{}} type="text" required={true} value={this.state.extraFields.UAEVisa} />
                                                <label htmlFor="number">Do you have a valid UAE visa<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseGander'>
                                                    <div className="form-check">
                                                        <input id="visa-yes" className="form-check-input" type="radio" 
                                                        checked={this.state.extraFields.UAEVisa == 'true' ? true : null} name="uaevisa" value="Y"
                                                        onChange={(e) => this.setAnswerChangeHandler('UAEVisa','true')} />
                                                        <label className="form-check-label" htmlFor="visa-yes">Yes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="visa-no" className="form-check-input" type="radio"
                                                        checked={this.state.extraFields.UAEVisa == 'false' ? true : null} name="uaevisa" value="N"
                                                        onChange={(e) => this.setAnswerChangeHandler('UAEVisa','false')} />
                                                        <label className="form-check-label" htmlFor="visa-no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} onChange={()=>{}} type="text" required={true} value={this.state.extraFields.UAETicket} />
                                                <label>Do you have ticket to UAE<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseGander'>
                                                    <div className="form-check">
                                                        <input id="ticket-yes" className="form-check-input" type="radio"
                                                        checked={this.state.extraFields.UAETicket == 'true' ? true : null} name="ticketuae" value="Y"
                                                        onChange={(e) => this.setAnswerChangeHandler('UAETicket','true')} />
                                                        <label className="form-check-label" htmlFor="ticket-yes">Yes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="ticket-no" className="form-check-input" type="radio"
                                                        checked={this.state.extraFields.UAETicket == 'false' ? true : null} name="ticketuae" value="N"
                                                        onChange={(e) => this.setAnswerChangeHandler('UAETicket','false')} />
                                                        <label className="form-check-label" htmlFor="ticket-no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} onChange={()=>{}} type="text" required={true} value={this.state.extraFields.Accommodation} />
                                                <label>Do you have a Accomodation<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseGander'>
                                                    <div className="form-check">
                                                        <input id="accomm-yes" className="form-check-input" type="radio" 
                                                        checked={this.state.extraFields.Accommodation == 'true' ? true : null} name="accomm" value="Y"
                                                        onChange={(e) => this.setAnswerChangeHandler('Accommodation','true')} />
                                                        <label className="form-check-label" htmlFor="accomm-yes">Yes</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="accomm-no" className="form-check-input" type="radio"
                                                        checked={this.state.extraFields.Accommodation == 'false' ? true : null} name="accomm" value="N"
                                                        onChange={(e) => this.setAnswerChangeHandler('Accommodation','false')} />
                                                        <label className="form-check-label" htmlFor="accomm-no">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
     
                                
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Next</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                    </form>
        )}
    
}

export default ExtraQuestion