import React from 'react';
import 'isomorphic-fetch';
import UploadFiles from '../Shared/UploadFiles';
import InputDropDown from '../inputs/DropDown/DropDown';
 
class CompnyQuestion extends React.Component {
    compnyFields = {
            industry: '',
            position: '',
            country: '',
            name: '',
            age: '',
            size: '',
            city: '',
            webSite: '',
        }
    state = {
        compnyFields: this.compnyFields
    };

    fieldsChangeHandler = (e,field) => {
        let compnyFields = Object.assign({}, this.state.compnyFields);
        if(field == 'webSite') e = e.toLowerCase()
        compnyFields[field] = e;
        this.setState({compnyFields});
    }
    setAge = (age)=>{
        this.fieldsChangeHandler(age,'age')
    }

    companySizeChangeHandler = (size) => {
        let compnyFields = Object.assign({}, this.state.compnyFields);
        compnyFields.size = size;
        this.setState({compnyFields});
    }

    Submission = (event)=> {
        event.preventDefault();
        
        let compnyFields = Object.assign({}, this.state.compnyFields);
        this.props.onSubmit(event,compnyFields)
        this.setState({compnyFields : this.compnyFields});
    }

    render(){
        return (
            <form  onSubmit={this.Submission} id="basicQuestionFrom">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Industry<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your industry" 
                                                    value={this.state.compnyFields.industry}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'industry')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Position<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your industry position" 
                                                    value={this.state.compnyFields.position}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'position')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter Country Business<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your country business" 
                                                    value={this.state.compnyFields.country}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'country')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Company Name<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true} 
                                                    pattern="[a-zA-Z0-9 ]+"
                                                    data-error="Please enter your company name" 
                                                    value={this.state.compnyFields.name}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'name')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label>Enter your Company Age<span style={{color : 'red'}}>*</span></label>
                                                <InputDropDown List={
                                                                [
                                                                    {value : '0-5',text : '0-5 years'},
                                                                    {value : '6-10',text : '6-10 years'},
                                                                    {value : '11-15',text : '11-15 years'},
                                                                    {value : '16+',text : '16+ years'},
                                                                ]} className='form-control' value={this.setAge}/>
                                                {/* <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    required={true} 
                                                    data-error="Please enter your company age" 
                                                    value={this.state.compnyFields.age}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'age')}
                                                /> */}
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                            <input style={{opacity: '0',position: 'absolute',width: '0px',height: '24px'}} onChange={()=>{}} type="text" required={true} value={this.state.compnyFields.size} />
                                                <label>Choose Your Company Size<span style={{color : 'red'}}>*</span></label>
                                                <div className='chooseCompanySize'>
                                                    <div className="form-check">
                                                        <input id="Check-size1" className="form-check-input" type="radio" 
                                                        checked={this.state.compnyFields.size == 'size1' ? true : null} name="CompanySize" value="size1"
                                                        onChange={(e) => this.companySizeChangeHandler('size1')} />
                                                        <label className="form-check-label" htmlFor="Check-size1">{"5 - 10 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size2" className="form-check-input" type="radio" 
                                                        checked={this.state.compnyFields.size == 'size2' ? true : null} name="CompanySize" value="size2"
                                                        onChange={(e) => this.companySizeChangeHandler('size2')} />
                                                        <label className="form-check-label" htmlFor="Check-size2">{"15 - 20 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size3" className="form-check-input" type="radio"
                                                        checked={this.state.compnyFields.size == 'size3' ? true : null} name="CompanySize" value="size3"
                                                        onChange={(e) => this.companySizeChangeHandler('size3')} />
                                                        <label className="form-check-label" htmlFor="Check-size3">{"25 - 40 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size4" className="form-check-input" type="radio"
                                                        checked={this.state.compnyFields.size == 'size4' ? true : null} name="CompanySize" value="size4"
                                                        onChange={(e) => this.companySizeChangeHandler('size4')} />
                                                        <label className="form-check-label" htmlFor="Check-size4">{"50 - 100 employes"}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input id="Check-size5" className="form-check-input" type="radio"
                                                        checked={this.state.compnyFields.size == 'size5' ? true : null} name="CompanySize" value="size5"
                                                        onChange={(e) => this.companySizeChangeHandler('size5')} />
                                                        <label className="form-check-label" htmlFor="Check-size5">{"100 and more"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter Company City<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control"  
                                                    required={true} 
                                                    pattern="[a-zA-Z ]+"
                                                    data-error="Please enter your company city" 
                                                    value={this.state.compnyFields.city}
                                                    onChange={(e) => this.fieldsChangeHandler(e.target.value,'city')}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Enter your Your Company Website<span style={{color : 'red'}}>*</span></label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    required={true}
                                                    value={this.state.compnyFields.webSite.toLowerCase()}
                                                    onChange={(e) => {this.fieldsChangeHandler(e.target.value,'webSite'); }}
                                                    pattern="^(http:\/\/www\.|https:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                                                />
                                            </div>
                                        </div>
     
                                
                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Next</button>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                    </form>
        )}
    
}

export default CompnyQuestion