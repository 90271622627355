import React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/images/HURUN ME logo 4.svg';
 
class Navigation extends React.Component {

    state = {
        collapsed: true,
        isOpen: false
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    pushNewState = ()=>{
        console.log('====> next loc', window.location.pathname)
        window.history.pushState("", document.title +' '+window.location.pathname, window.location.pathname);
        this.setState({ isOpen: false,collapsed :true });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            // if (window.scrollY > 170) {
            //     elementId.classList.add("is-sticky");
            // } else {
                //     elementId.classList.remove("is-sticky");
                // }
            });

        window.history.pushState("", document.title, window.location.pathname);
        window.scrollTo(0, 0);
    }



    render(){
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <header id="header" className="header-area">
                <div id="navbar" className="elkevent-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/" onClick={this.pushNewState}>
                                <img src={logo} alt="logo" width="100%" />
                            </Link>

                            <button 
                                onClick={this.toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" 
                                aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <Link 
                                            // exact="true" 
                                            // to="#" 
                                            to="/" 
                                            className="nav-link"
                                            
                                        >
                                            Home
                                        </Link>

                    
                                    </li>
                                    
                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <Link 
                                            to="#" 
                                            className="nav-link"
                                        >
                                            About us
                                        </Link>
                                        
                                        <ul className={menuClass}>
                                            <li className="nav-item" onClick={this.pushNewState}>
                                                <Link to="/about-hurun" className="nav-link">Hurun Report</Link>
                                               
                                            </li>

                                            <li className="nav-item" onClick={this.pushNewState}>
                                                <Link to="/sangsbeel-group" className="nav-link">Sangsbeel Group</Link>
                                                
                                            </li>

                                           
                                        </ul>
                                    </li>

                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <NavLink 
                                            to="/sponsors" 
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Event Sponsors
                                        </NavLink>
                                    </li>

                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <NavLink 
                                            to="/Events" 
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Events
                                        </NavLink>
                                    </li>
                                    
                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <NavLink 
                                            to="/reports" 
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Hurun Reports
                                        </NavLink>
                                    </li>

                                    <li className="nav-item" onClick={this.pushNewState}>
                                        <NavLink 
                                            to="/contact" 
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Contact us
                                        </NavLink>
                                    </li>
                                </ul>

                                <div className="others-option">
                                    <ul>
                                        <li onClick={this.pushNewState}>
                                            <NavLink 
                                                to="/join-hurun" 
                                                className="btn btn-primary" 
                                                onClick={this.toggleNavbar}
                                            >
                                                Join Hurun UAE Summit
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default withRouter(Navigation);