import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import TableResponsive from '../Shared/TableResponsive';
import TablePlans from '../Shared/MobileTable/Table';
 
class SponsorsShipPlan extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    isMobile = window.innerWidth <= 768
    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section className="schedule-area bg-image ptb-120">
                <div className="container">
                    <div className="section-title">
                        <span>HURUN Sponsorship</span>
                        <h2>Types of <b>Sponsorship</b></h2>

                        <LaxDiv text="Sponsorship" dataPreset="driftLeft" />

                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        {
                            this.isMobile ? <>
                            <TablePlans titel="Hurun Main Sponsor" hash="main" rows={
                                [
                                    {titel : 'Time at The Discussion Panel',content : '45 Minutes'},
                                    {titel : 'Invitations To Attend The Event',content : '15 Invitations'},
                                    {titel : 'Sponsor Booth in Exhibition Area',content : '150 sq. meters'},
                                    {titel : 'Tables at The Gala Dinner Event',content : '3 Tables'},
                                    {titel : 'Pages on Hurun Report Printed Magazine',content : '2 Full Pages'},
                                    {titel : 'Pages on The event Brochure For the advertisement',content : '2 Full Pages'},
                                    {titel : 'Advertisement Clip to be played at the event',content : '60 Seconds'},
                                    {titel : 'Interview With Mr. Robert Hoogwerf',content : '60 Minutes'}
                                ]
                            }></TablePlans>
                             <TablePlans titel="Hurun Platinum Sponsor" hash="Platinum" rows={
                                [
                                    {titel : 'Time at The Discussion Panel',content : '30 Minutes'},
                                    {titel : 'Invitations To Attend The Event',content : '25 Invitations'},
                                    {titel : 'Sponsor Booth in Exhibition Area',content : '55 sq. meters'},
                                    {titel : 'Tables at The Gala Dinner Event',content : '3 Tables'},
                                    {titel : 'Pages on Hurun Report Printed Magazine',content : '1 Full Pages'},
                                    {titel : 'Pages on The event Brochure For the advertisement',content : '2 Full Pages'},
                                    {titel : 'Advertisement Clip to be played at the event',content : '45 Seconds'},
                                    {titel : 'Interview With Mr. Robert Hoogwerf',content : '30 Minutes'}
                                ]
                            }></TablePlans>
                            <TablePlans titel="Hurun Gold Sponsor" hash="Gold" rows={
                                [
                                    {titel : 'Time at The Discussion Panel',content : '15 Minutes'},
                                    {titel : 'Invitations To Attend The Event',content : '15 Invitations'},
                                    {titel : 'Sponsor Booth in Exhibition Area',content : '42 sq. meters'},
                                    {titel : 'Tables at The Gala Dinner Event',content : '2 Tables'},
                                    {titel : 'Pages on Hurun Report Printed Magazine',content : '1/2 Full Pages'},
                                    {titel : 'Pages on The event Brochure For the advertisement',content : '1/2 Full Pages'},
                                    {titel : 'Advertisement Clip to be played at the event',content : '30 Seconds'},
                                    {titel : 'Interview With Mr. Robert Hoogwerf',content : '15 Minutes'}
                                ]
                            }></TablePlans>
                            <TablePlans titel="Hurun Silver Sponsor" hash="Silver" rows={
                                [
                                    {titel : 'Time at The Discussion Panel',content : '- Minutes'},
                                    {titel : 'Invitations To Attend The Event',content : '10 Invitations'},
                                    {titel : 'Sponsor Booth in Exhibition Area',content : '21 sq. meters'},
                                    {titel : 'Tables at The Gala Dinner Event',content : '1 Tables'},
                                    {titel : 'Pages on Hurun Report Printed Magazine',content : '- Full Pages'},
                                    {titel : 'Pages on The event Brochure For the advertisement',content : '1/2 Full Pages'},
                                    {titel : 'Advertisement Clip to be played at the event',content : '20 Seconds'},
                                    {titel : 'Interview With Mr. Robert Hoogwerf',content : '- Minutes'}
                                ]
                            }></TablePlans>
                            </> : <table className="tablePlans">
                            <thead>
                                <tr>
                                    <th>Benefits</th>
                                    <th>Hurun Main Sponsor</th>
                                    <th>Hurun Platinum Sponsor</th>
                                    <th>Hurun Gold Sponsor</th>
                                    <th>Hurun Silver Sponsor</th>
                                </tr>
                            </thead>
                            <tbody> 
                        
                            <tr>
                                            <td>Time at The Discussion Panel</td>
                                            <td>45<br /><span>Minutes</span></td>
                                            <td>30<br /><span>Minutes</span></td>
                                            <td>15<br /><span>Minutes</span></td>
                                            <td>-<br /><span>Minutes</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Invitations To Attend The Event</td>
                                            <td>30<br /><span>Invitations</span></td>
                                            <td>25<br /><span>Invitations</span></td>
                                            <td>15<br /><span>Invitations</span></td>
                                            <td>10<br /><span>Invitations</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Sponsor Booth in Exhibition Area</td>
                                            <td>150<br /><span>sq. meters</span></td>
                                            <td>55<br /><span>sq. meters</span></td>
                                            <td>42<br /><span>sq. meters</span></td>
                                            <td>21<br /><span>sq. meters</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Tables at The Gala Dinner Event</td>
                                            <td>3<br /><span>Tables</span></td>
                                            <td>3<br /><span>Tables</span></td>
                                            <td>2<br /><span>Tables</span></td>
                                            <td>1<br /><span>Tables</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Pages on Hurun Report Printed Magazine</td>
                                            <td>2<br /><span>Full Pages</span></td>
                                            <td>2<br /><span>Full Pages</span></td>
                                            <td>1/2<br /><span>Full Pages</span></td>
                                            <td>1/2<br /><span>Full Pages</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Pages on The event Brochure For the advertisement</td>
                                            <td>2<br /><span>Full Pages</span></td>
                                            <td>2<br /><span>Full Pages</span></td>
                                            <td>1/2<br /><span>Full Pages</span></td>
                                            <td>-<br /><span>Full Pages</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Advertisement Clip to be played at the event</td>
                                            <td>60<br /><span>Seconds</span></td>
                                            <td>45<br /><span>Seconds</span></td>
                                            <td>30<br /><span>Seconds</span></td>
                                            <td>20<br /><span>Seconds</span></td>
                                        </tr>
                                                    
                                        <tr>
                                            <td>Interview With Mr. Robert Hoogwerf</td>
                                            <td>60<br /><span>Minutes</span></td>
                                            <td>30<br /><span>Minutes</span></td>
                                            <td>15<br /><span>Minutes</span></td>
                                            <td>-<br /><span>Minutes</span></td>
                                        </tr>
                                                    
                                        {/* <tr>
                                            <td>Total Package Cost</td>
                                            <td>Starts at<br /><span>USD 1 Million</span></td>
                                            <td>USD 750<br /><span>USD 1 Million</span></td>
                                            <td>USD 550<br /><span>USD 1 Million</span></td>
                                            <td>USD<br /><span>USD 1 Million</span></td>
                                        </tr> */}
                                        <tr >
                                            <td></td>
                                            <td>
                                                <Link to="/applySponsor#main" className="btn btn-primary">Apply Now</Link>
                                            </td>
                                            <td>
                                                <Link to="/applySponsor#Platinum" className="btn btn-primary">Apply Now</Link>
                                            </td>
                                            <td>
                                                <Link to="/applySponsor#Gold" className="btn btn-primary">Apply Now</Link>
                                            </td>
                                            <td>
                                                <Link to="/applySponsor#Silver" className="btn btn-primary">Apply Now</Link>
                                            </td>
                                        </tr>
                                  
                            </tbody>
                        </table>
                        }
                        
                    </div>
                </div>

                <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div>
                <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div>
                <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div>
                <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div>
            </section>
        );
    }
}
 
export default SponsorsShipPlan;