import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxButton from '../Shared/LaxButton';
 
class About extends React.Component {
    
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="about-area ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <span>About Hurun</span>
                                <a href="https://www.hurun.net/" target="_blank"><h2>Hurun Report</h2></a>
                                
                                <p style={{textAlign: 'justify'}}>Hurun Report is a leading research, luxury publishing and events group established in London in 1999 with presence in China, India, France, UK, USA, Australia, Japan, Canada and Luxembourg. 
									It is widely recognized worldwide for its comprehensive evaluation of the wealthiest individuals across the globe. Hurun Report is the largest compiler of the rich list globally.</p>
                                <br />
                                <p style={{textAlign: 'justify'}}>Hurun Report Inc. has four divisions: Hurun Report Media, a stable of digital media and four magazines; Hurun Research Institute; Hurun Conferences, an active events division targeting entrepreneurs and high net worth individuals; and Hurun Investments,
									 a USD20m early-stage venture capital fund with investments into tech, media, retail and education.</p>
                                <br />
                                
								<h2>Hurun Middle East DMCC</h2>
                                <p style={{textAlign: 'justify'}}>Hurun Middle East DMCC was established in July 2022 with its license issued by Dubai Multi Commodities Centre Authority (DMCCA) in Jumeirah Lakes Towers, Dubai, United Arab Emirates. DMCC is the world's flagship free zone and recipient of multiple international prestigious awards.</p>
                                <br />
								<p>Hurun Middle East in coordination with Sangsbeel Group based in Dubai, United Arab Emirates, organizes Hurun UAE Summit 2023. The summit shall release the Hurun UAE Under30 for young entrepreneurs of all ethnic background a platform to excel, and the Top Developer/Retailer Awards.</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/image00004.jpg")} style={{top: '0px',maxHeight: '400px'}} className="about-img1" alt="about" />

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}
 
export default About;