import React from 'react';
 
class TablePlansRow extends React.Component {
    render(){
        return (
            <div className="s-table-row">
                <label className="form-label">{this.props.titel}</label>
                <label className="form-label">{this.props.content}</label>
            </div>
        );
    }
}
 
export default TablePlansRow;