import React from 'react';
import { Link } from 'react-router-dom';
 
class BuyTicket extends React.Component {
    render(){
        return (
            <section className="buy-tickets-area ptb-120">
                <div className="buy-tickets">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <span>Join Hurun UAE Summit 2023</span>
                                    <h2>Get Your Visitor Pass</h2>
                            
                                    {/* <p>t dolore magna aliqua. Ut enim ad minim.</p> */}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="buy-ticket-btn">
                                    <Link to="/apply" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default BuyTicket;