import React from 'react';
import { Link } from 'react-router-dom';

class MainBanner extends React.Component {
    render(){
        return (
            <div className="page-title-area item-bg-report">
                <div className="container">
                    {/* <h1>Hurun Report</h1>
                    {//<span>One Team, One Dream</span> }
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li>Hurun Report</li>
                    </ul> */}
                </div>
            </div>
        );
    }
}
 
export default MainBanner;