import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Event Location</h3>
                                <span style={{fontSize: '21px'}}>
                                    <i className="icofont-calendar"></i> 17-19 March, 2023
                                </span>

                                <span className="location"  style={{fontSize: '21px'}}>
                                    <a href='https://goo.gl/maps/9mnZ6SFFM1pvUhEH9' target="_blank" style={{fontSize : 'unset'}}>
                                        <i className="icofont-google-map"></i> Emirates Palace, Abu Dhabi
                                    </a>
                                </span>

                                <a href="tel:+97143814222" className="contact-authority"  style={{fontSize: '21px'}}>
                                    <i className="icofont-phone"></i> +971 4 381 4222
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Social Media</h3>
                                <p>Don't miss a thing! Connect with us on our social media platforms to stay updated.</p>
                                
                                <ul className="social-links">
                                        <li>
                                            <a href="https://www.facebook.com/hurunme" target="_blank">
                                                <i className="icofont-facebook"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" target="_blank">
                                                <i className="icofont-twitter"></i>
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="https://www.instagram.com/hurunuae/" target="_blank">
                                                <i className="icofont-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/hurun-middle-east-dmcc/" target="_blank">
                                                <i className="icofont-linkedin"></i>
                                            </a>
                                        </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="copyright-area">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={require("../../assets/images/HURUN ME logo 4.svg")} alt="logo" width="100%"/>
                                    </Link>
                                </div>
                                {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                                <p>
                                    Copyright <i className="icofont-copyright"></i> 2023 No9ine Agency. All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;