import React, {Component } from "react";
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import JsonAnim from '../../assets/video/successful.json'

class Successfully extends Component {

    componentDidMount() {
        let pos = document.getElementById('successfullyViwe').offsetTop
        window.scrollTo({top: pos-70, behavior: 'smooth'});
    }

    render() {
        return (
            <div id="successfullyViwe" className="successfully">
                <div>
                    <Lottie speed={1} animationData={JsonAnim} play style={{ width: 150 }}/>
                </div>
                <div style={{margin : '30px 0px'}}><span>Awesome !</span></div>
                <div>
                <p>{this.props.apply ? 'Thank you for applying as a visitor' : 'Thank you for contacting us'}<br />for the Hurun UAE Summit.
                <span >Our Team will contact you soon</span></p>
                    {/* <p>{this.props.apply ? 'Thank you for applying as a visitor' : 'Thank you for contacting us'}<br />for the Hurun UAE Summit. <br /><br /> Our Team will contact you soon</p> */}
                </div>
            </div>
        )
    }
}

export default Successfully;
