import React from 'react';
import 'isomorphic-fetch';
import BasicQuestion from '../../Question/BasicQuestion';
 
class AskBasicInfo extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    render(){
        return (<BasicQuestion buttonText="Next" upload={false} onSubmit={this.props.onSubmit} />);
    }
}
 
export default AskBasicInfo;