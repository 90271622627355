import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import TableResponsive from '../Shared/TableResponsive';
 
class EventSchedules extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section className="schedule-area bg-image ptb-120">
                <div className="container">
                    <div className="section-title">
                        <span>HURUN REPORT</span>
                        <h2>Hurun <b>Reports</b></h2>

                        <LaxDiv text="REPORTS" dataPreset="driftLeft" />

                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <ul className="tabs active">
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab1')}
                                        className="current"
                                    >
                                        <Link to="#">
                                        Hurun Global Rich List
                                        </Link>
                                    </li>

                                    <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                        <Link to="#">
                                        Hurun Global Real Estate Rich List
                                        </Link>
                                    </li>

                                    <li onClick={(e) => this.openTabSection(e, 'tab3')}>
                                        <Link to="#">
                                        Hurun U30s to Watch
                                        </Link>
                                    </li>

                                    <li onClick={(e) => this.openTabSection(e, 'tab4')}>
                                        <Link to="#">
                                        Hurun Canada Unicorn Index
                                        </Link> 
                                    </li>
                                </ul>

                                <div className="tab_content">

                                    
                                    <div id="tab1" className="tabs_item">
                                        <TableResponsive tab="1"></TableResponsive>	
                                    </div>

                                    <div id="tab2" className="tabs_item">
                                        <TableResponsive tab="2"></TableResponsive>
                                    </div>

                                    <div id="tab3" className="tabs_item">
                                        <TableResponsive tab="3"></TableResponsive>
                                    </div>

                                    <div id="tab4" className="tabs_item">
                                        <TableResponsive tab="4"></TableResponsive>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape1"><img src={require("../../assets/images/shapes/1.png")} alt="shape1" /></div>
                <div className="shape2 rotateme"><img src={require("../../assets/images/shapes/2.png")} alt="shape2" /></div>
                <div className="shape3 rotateme"><img src={require("../../assets/images/shapes/3.png")} alt="shape3" /></div>
                <div className="shape4"><img src={require("../../assets/images/shapes/4.png")} alt="shape4" /></div>
            </section>
        );
    }
}
 
export default EventSchedules;