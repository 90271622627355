import React, { Component } from "react";
import EventDetailsWrapper from "./EventDetailsWrapper";
import "./assets/css/all.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/bootstrap-icons.css";
class EventDetails extends Component {
  render() {
    return (
      <>
        <EventDetailsWrapper />
      </>
    );
  }
}

export default EventDetails;
