import React, {Component } from "react";
import "../shared/style.css";

class InputDropDown extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            input : '',
            open : false
        }
      }
    
    
    onChangeHandler = (e)=>{

        //this.setState({input : e.target.value})
    }
    selectFromList = (selected)=>{
        this.setState({open : false})
        this.setState({input : selected})
        this.props.value(selected)
        // this.myRef
    }

    render() {
        return (
            <div className="DropinputSearch">
                <input 
                type="text" 
                ref={this.myRef}
                placeholder="Select" 
                className={this.props.className} 
                onChange={this.onChangeHandler}
                onFocus={()=> this.setState({open : true})}
                onBlur={()=> setTimeout(() => {this.setState({open : false})}, 200)}
                value={this.state.input}/>
                <ul style={{display : (this.state.open ? 'flow-root' : 'none')}} >
                    {
                        this.props.List.map((item,index) =>{
                            return <li key={'li-'+index} onClick={()=> this.selectFromList(item.value)}>
                                        <div><span>{item.text}</span></div>
                                    </li>
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default InputDropDown;
