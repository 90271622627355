import React, { useState } from 'react';
import 'isomorphic-fetch';
import LaxDiv from '../Shared/LaxDiv';
import StepsTabs from '../Shared/StepsTabs';
import SponsorFirstQuestion from '../Question/SponsorFirstQuestion';
import SponsorSecondQuestion from '../Question/SponsorSecondQuestion';
import { Link } from 'react-router-dom';
import Successfully from '../Shared/successfully';


class SponsorApply extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        },
		plan : window.location.hash,
    };

    render(){
        return (
            <section className="speakers-area ptb-120 bg-image1">
            <div className="container">
                <div className="section-title">
                    <span>HURUN EVENT</span>
                    <h2>Join Hurun UAE Summit 2023 as a <b>Sponsor</b></h2>
                    <div className="bar"></div>
                    <LaxDiv text="Sponsor" dataPreset="driftRight" />
                </div>
      
            <div className="row h-100 ptb-24 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <p>The Hurun awards are given on a yearly basis to recognize organizations and individuals who have made outstanding achievements in their own business fields as well as people who have made significant contributions to the community</p>
                                
                                <p>Experience the latest and newest innovations from UAE's brightest minds that are revolutionizing different industries from Film making to real estate and beyond.</p>

                                <div className="signature">
                                    <img style={{visibility: 'hidden'}} src={require("../../assets/images/HURUN ME logo 4.svg")} alt="signature" />
                                </div>

        
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={require("../../assets/images/Photo+0857 copy.jpg")} className="about-img3" alt="about" />

                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'center',display : `${this.state.submitted ? 'none' : 'inherit'}`,fontSize: '24px',fontWeight: 800,marginBottom: '30px',marginTop: '60px'}}>Apply Now</div>
                    <div id="sponsorApply">
                    {this.state.submitted ? 
                    <Successfully></Successfully> :
                        <StepsTabs postAs="Sponsor" plan={this.state.plan} steps={[{text : "Step 1",tab : SponsorFirstQuestion},{text : "Step 2",tab : SponsorSecondQuestion}]}/>
                    }
                    </div>

                    <Link to="/applySponsorPage" className="back btn btn-primary">Back</Link>
                    
            </div>
            
        </section>
        );
    }
}

 
export default SponsorApply;