import React from 'react';
import { Link } from 'react-router-dom';
 
class WhyUs extends React.Component {
    render(){
        return (
            <section className="why-choose-us">
                <div className="row m-0">
                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-headphone-alt-1"></i>
                                        </div>
                                        <h3>Great Speakers</h3>
                                        <p>Get to listen to Great Speakers from all around the world and learn from their experience.</p>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-network-tower"></i>
                                        </div>
                                        <h3>Networking</h3>
                                        <p>Get to meet with Big investors and Entrepreneurs From all around the world.</p>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-ebuddy"></i>
                                        </div>
                                        <h3>Interactive Shows</h3>
                                        <p>Live entertainment from Live shows, Interactive technology, live Music shows for an Unforgettable experience .</p>
            
                                        {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-wink-smile"></i>
                                        </div>
                                        <h3>Investment opportunity</h3>
                                        <p>Benefit from the Event Sponsors who will be in the event to offer their best services.</p>
            
                                        {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className='slideshow'>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </ul>
            </section>
        );
    }
}
 
export default WhyUs;