import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import HurunMainSponsor from '../LaxButton/HurunMainSponsor';
// import HurunPlatinumSponsor from '../LaxButton/HurunPlatinumSponsor';
// import HurunGoldSponsor from '../LaxButton/HurunGoldSponsor';
// import HurunSilverSponsor from '../LaxButton/HurunSilverSponsor';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        400: {
            items:3,
        },
        576: {
            items:3,
        },
        768: {
            items:4,
        },
        1200: {
            items:5,
        }
    }
}

const options1 = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        400: {
            items:2,
        },
        576: {
            items:2,
        },
        768: {
            items:2,
        },
        1200: {
            items:2,
        }
    }
}
 
class Partner extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (<></>
            // <section className="partner-area ptb-120">
            //     <div className="container">
            //         <div className="section-title">
            //             <span>Check Who Makes This Event Possible!</span>
            //             <h2>Our Event <b>Partners</b></h2>

            //             <Link to="/applySponsorPage" className="btn btn-primary">Become a Sponsor</Link>

            //             <div className="bar"></div>
            //         </div>
                    
            //         <div className="row">
            //             <div className="col-lg-12">
            //                 <div className="partner-title HurunMainSponsor">
            //                     <HurunMainSponsor />
            //                 </div>
            //             </div>
            //             <OwlCarousel 
            //                 className="platinum-partner-slides owl-carousel owl-theme"
            //                 {...options1}
            //             >
            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                             <img src={require("../../assets/images/tourism 2.png")} alt="Partner Logo" />
            //                             {/* <img src={require("../../assets/images/tourism 2.png")} alt="Partner Logo" /> */}
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                             <img src={require("../../assets/images/economic-1 3.png")} alt="Partner Logo" />
            //                             {/* <img src={require("../../assets/images/economic-1 3.png")} alt="Partner Logo" /> */}
            //                     </div>
            //                 </div>

            //             </OwlCarousel>

            //             {/* <div className="col-lg-12">
            //                 <div className="border"></div>
            //             </div>

            //             <div className="col-lg-12">
            //                 <div className="partner-title HurunPlatinumSponsor">
            //                     <HurunPlatinumSponsor />
            //                 </div>
            //             </div>

            //             <OwlCarousel 
            //                 className="gold-partner-slides owl-carousel owl-theme"
            //                 {...options}
            //             >
            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>
            //             </OwlCarousel>

            //             <div className="col-lg-12">
            //                 <div className="border"></div>
            //             </div>

            //             <div className="col-lg-12">
            //                 <div className="partner-title HurunGoldSponsor">
            //                     <HurunGoldSponsor />
            //                 </div>
            //             </div>

            //             <OwlCarousel 
            //                 className="gold-partner-slides owl-carousel owl-theme"
            //                 {...options}
            //             >
            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>
            //             </OwlCarousel>

            //             <div className="col-lg-12">
            //                 <div className="border"></div>
            //             </div>

            //             <div className="col-lg-12">
            //                 <div className="partner-title HurunSilverSponsor">
            //                     <HurunSilverSponsor />
            //                 </div>
            //             </div>

            //             <OwlCarousel 
            //                 className="gold-partner-slides owl-carousel owl-theme"
            //                 {...options}
            //             >
            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner1.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner2.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner3.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner4.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>

            //                 <div className="col-lg-12 col-md-12">
            //                     <div className="partner-item">
            //                         <Link to="/sponsors" target="_blank">
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                             <img src={require("../../assets/images/gold-partner5.png")} alt="Partner Logo" />
            //                         </Link>
            //                     </div>
            //                 </div>
            //             </OwlCarousel> */}
            //         </div>
            //     </div>
            // </section>
        );
    }
}
 
export default Partner;