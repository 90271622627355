import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/HurunMainSponsor';
import GoldSponsors from '../LaxButton/HurunPlatinumSponsor';
import Footer from '../Common/Footer';
import Partner from '../Common/Partner';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1200: {
            items:5,
        }
    }
}
 
class Sponsor extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>

                <div className="page-title-area item-bg-sponsers">
                    <div className="container">
                        {/* <h1>Sponsors</h1>
                        <span>Check Who Makes This Event Possible!</span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Sponsors</li>
                        </ul> */}
                    </div>
                </div>



                <section className="partner-area">
                    <div className="container">
                        <Partner />
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default Sponsor;