import React from 'react'
import CropperX from './Cropper';


class UploadFiles extends React.Component {

    state = {
        empty: 'true',
        thePosition: false,
        files: [],
        media : [],
        fileVaule : '',
        required : true,
        toCrop : null
    };

    componentDidMount() {
       
    }

    componentWillUnmount() {
        
    }
    setImageCroped(newFile,original){

        this.setState({toCrop : null,
            files: this.state.files.map(data=>{
                console.log(data);
                if(data.file.name == original.name){
                    data.file = newFile
                    
                }
                return data
            })
        });
        
        
        console.log('<-------->',this.state)

    }
    onClickBrowse = (e)=> {
        document.getElementById('choiceFile').click();
    }
    FileExist = (fileName)=>{
        for (const data of this.state.files) {
            console.log(data.file.name == fileName,data.file.name,fileName)
            if (data.file.name == fileName) return true
        }
        return false
    }
    onChangeInput = (e) => {
        let value =  e.target.value
        if (value !== '' && !this.FileExist(e.target.files[0].name)) {
            let tFile = e.target.files[0]
            let validExtensions = ["image/jpeg", "image/jpg", "image/png","application/pdf"]; //adding some valid image extensions in array
            let isImg = ["image/jpeg", "image/jpg", "image/png"]
            tFile.state = 'preparing'//'uploaded'
            tFile.error = !validExtensions.includes(tFile.type)

            tFile.progress = 0
            tFile.id = 0
            console.log(tFile)
            this.setState(prevState => ({
                empty: 'false',
                toCrop : isImg.includes(tFile.type) ? tFile : null,
                files: [...prevState.files,{file : tFile}]
              }));
        }
    }

    onClickUpload = async (e)=>{
        for (const index in this.state.files) {
            if(this.state.files[index].file.error || this.state.files[index].file.state == 'uploaded') continue
            this.handleUpload(index)
        }

        // for (const data of this.state.files) {
        //     for (let i = 0; i < 100; i++) {
        //         if (data.file.error) continue 
        //         await setTimeout(() => { data.file.progress = i 
        //             if(i > 98) data.file.state = 'uploaded'
        //             this.setState(prevState => ({
        //                 files: this.state.files[index]
        //               }));
        //             console.log('--------> ',data.file.progress,data.file.state)
                    
        //         }, 2000);
        //     }
        //     if('uploaded' in this.props) this.props.uploaded.push(data.file.name)
        // }
    }
    setProgress = (index,pros,state = 'uploading',error = false,errorServer = '',id = 0) => {
        const newFiles = [...this.state.files];
        newFiles[index].file.progress = parseInt(pros);
        newFiles[index].file.state = state
        newFiles[index].file.error = error
        newFiles[index].file.id = id
        if(error) newFiles[index].file['errorServer'] = errorServer
        this.setState({ files: newFiles });
    }
    handleUpload = (index) => {
        const formData = new FormData();
        formData.append('file', this.state.files[index].file);
    
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', 'https://hurunuae.ae/admin/uploadfiles.php');
        xhr.upload.onprogress = event => {
            console.log('------> ',event.loaded / event.total * 100)
            this.setProgress(index,event.loaded / event.total * 100)
        };
        xhr.addEventListener('load', () => {
            
            if (xhr.status == 200) {
                let rep = JSON.parse(xhr.responseText)
                if (rep.status) {
                    this.setState({ required: false });
                    this.setProgress(index,100,'uploaded',false,'',rep.mediaID)
                    if('uploaded' in this.props) this.props.uploaded.push(rep.mediaID) 
                }else{
                    this.setProgress(index,0,'error',true,rep.error)
                }
            }
            console.log({ status: xhr.status, body: xhr.responseText })
        });
        xhr.send(formData);
    }
    onDropFile = (e)=>{
        console.log(e)
    }
    ondragOverFile = (files, event)=>{
        console.log(files,event)
    }
    removeFile = (fileName) => {
        console.log(fileName)
        this.setState(prevState => ({
          files: prevState.files.filter(data => data.file.name != fileName),toCrop : null
        }));
        console.log(this.state.files)
    }
    removeFileUploaded = (fileName) => {
        for (const obj of this.state.files) {
            if(obj.file.name == fileName) this.props.uploaded = this.props.uploaded.filter(data => data.file.id != obj.file.id)
        }
        this.setState(prevState => ({
          files: prevState.files.filter(data => data.file.name != fileName)
        }));
    }

    render(){
        return (
            <React.Fragment>
                {this.state.toCrop != null ? <CropperX 
                                                        imageFile={this.state.toCrop} 
                                                        onSave={(blob,original)=> this.setImageCroped(blob,original)}
                                                        onClose={(filename)=>this.removeFile(filename)}
                                                        /> : <></>}
                
                <div className="uploadFilesConteiner" empty={this.state.files.length > 0 ? 'false' : 'true'}>
                    
                    <div><span className="uploadtxt">Upload</span></div>
                        <div className="uploadFilesArea" onDrop={e => console.log(e)}>
                            <input id='choiceFile' type="file" value={this.state.fileVaule} required={this.state.required} onChange={this.onChangeInput}/>
                            <div>
                                <svg className="bi bi-cloud-upload" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"></path>
                                    <path fillRule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"></path>
                                </svg>
                            </div> 
                                <div><span >Drag &amp; drop files or <label onClick={this.onClickBrowse}>Browse</label></span>
                                </div>
                            <div><span className="GrayFont">Supported formates : JPEG,PNG,PDF</span></div>
                        </div>
                        
                        <div className="selectedFilesArea"  >
                              {
                                    this.state.files.filter(data => data.file.state != 'uploaded').length > 0 ?
                                    <div className="uploadingarea">
                                            <div><span className="GrayFont">Uploading - <strong>{this.state.files.filter(data => data.file.state == 'uploaded').length}</strong>/<strong>{
                                            this.state.files.length}</strong> files</span></div>
                                                <div className='uploadList'>
                                                    {this.state.files.map((data, index) => (
                                                        data.file.state != 'uploaded' ? <UploadingFile key={index} data={data} onClick={() => this.removeFile(data.file.name)}/> : ''
                                                    ))}
                                                </div>
                                    </div> : <></>
                                }  
                            <div className="uploadedarea">
                                    <div><span className="GrayFont">Uploaded</span></div>
                                        <div className='uploadList'>
                                        {this.state.files.map((data, index) => (
                                            data.file.state == 'uploaded' ? <UploadedFile key={index} data={data} onClick={() => this.removeFile(data.file.name)} /> : ''
                                        ))}
                                    </div>
                            </div>
                        </div>
                        <button className="btn btn-primary" type="button" onClick={this.onClickUpload}>UPLOAD FILES</button>
                    </div>
                    
            </React.Fragment>
        )
    }
}

function UploadingFile({ data, onClick }) {
    return (<><div className="uploadingFile"><label>{data.file.name}</label>
            <div className="uploadingProgress" style={{width: data.file.progress+'%'}}></div>
                <svg onClick={onClick} className="bi bi-x-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                </svg>
            </div>
            { data.file.error ? <span>{data.file.state == 'error' ? data.file.errorServer : "This document is not supported, please delte and upload another file."}</span> : <></>}
            
            </>)
}
function UploadedFile({ data, onClick }) {
    // console.log('----> ',props.data.file)
    return (<div className="uploaded"><label>{data.file.name}</label>
                <svg onClick={onClick} className="bi bi-trash border rounded-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                </svg> 
            </div>)
}

export default UploadFiles;
